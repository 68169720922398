<template>
    <section v-if="shorted">
        <div  v-if="computedElement && computedElement.document">
            <a
                title="Herunterladen"
                v-bind:href="downloadDocument(computedElement.document)"
                download
                target="_blank"
            >
                <i class="material-icons">attach_file</i>
            </a>
        </div>
    </section>
    <section v-else>
        <div  :class="{ 'hover-upload-area': !computedElement || !computedElement.document}" class="is-full-width has-text-centered" style="height: 100%;">
            <template v-if="computedElement && computedElement.document">
                <loading class="in-row is-small" v-show="isLoading" text-in-front="" text-after="" style="height: 30px; margin:0 0 0 5px; padding: 0; width: unset"></loading>

                <template v-if="!isLoading">
                    <input class="is-hidden" type="file" ref="fileUpload">
                    <div class="level" style="justify-content: center;height: 100%;">
                        <a
                            class="button is-max-100 has-text-grey pl-2 pr-2"
                            v-bind:href="downloadDocument(computedElement.document)"
                            download
                            target="_blank"
                        >
                            <span class="is-truncated mr-0">download</span>
                        </a>

                        <button
                            v-on:click="deleteDocument(computedElement.document)"
                        ><i class="material-icons">delete</i></button>
                    </div>
                </template>

            </template>
            <template v-else-if="typeof computedElement === 'undefined'">
                <p class="mt-2"></p>
            </template>
            <template v-else class="is-fullHeight">
                <vue-dropzone
                    v-if="hasUpload"
                    ref="dropzone"
                    id="element-dropzone"
                    :resize-height="500"
                    v-bind:options="dropzoneOptions"
                    v-on:vdropzone-sending="sendingEvent"
                    v-on:vdropzone-success="fileSuccess"
                    useCustomSlot
                >
                    <loading class="is-small" v-if="isLoading" text-in-front="" text-after="" style="height: 30px;"></loading>
                    <div id="documentDropZone" class="dz-dropzone is-in-row is-justify-content-center" v-else
                    ><i class="material-icons mr-0" style="font-size: 14px">upload</i></div>
                </vue-dropzone>
            </template>
        </div>
    </section>
</template>
<script>

import vue2Dropzone from 'vue2-dropzone';
import {authHeader, getFilePath} from "@utilities/functions";
import {axiosDelete} from "@api";
import Loading   from '@components/Loading';


export default {

    props: {
        row: {type: Object},
        element: { type: Object,  default: () => {} },
        options: { type: Object},
        shorted: { type: Boolean, default: false},
        hasUpload: { type: Boolean, default: true},
        elementProperty: {type: String, required: false},
        documentProperty: {type: String, required: false},
        elementApi: {type: String, required: false},
        additionalLinks: {type: Array, default: () => []},
        documentType: {type: String, default: 'general_info'},
        documentName: {type: String, default: ''}
    },
    data: function () {
        return {
            documents: [],
            isLoading: false,
            internalRow: this.row,
        }
    },

    methods: {
        sendingEvent: function (file, xhr, formData) {
            this.isLoading = true;
            let data = this.element ? this.element : this.internalRow;
            formData.append('name', this.documentName ? this.documentName : file.name.split('.').slice(0, -1).join('.'));
            let element = this.element ? this.element.id : this.internalRow.id
            data.links = this.additionalLinks;
            data.links.push({
                [this.documentProperty]: element ? this.elementApi + '/' + element : null
            })
            data.type = this.documentType;

            if (!!data && typeof data === 'object') {
                Object.keys(data).forEach(key => {
                    if (!!data[key]) {
                        if (key === 'links') {
                            data.links.forEach(link => {
                                formData.append('links[]', JSON.stringify(link))
                            })
                      } else {
                            formData.append(key, data[key])
                        }
                    }
                })
            }
        },

        fileSuccess: function (file, response) {
            this.documents = [...this.documents, response];
            this.isLoading = false;
            this.computedElement = {document: response};


            /*if (response.links) {
                response.links.map(link => {
                    if (link.invoice) {
                        link.document = response;
                        this.$attrs.row.lastOpenInvoiceItem.invoice.documentLink = link;
                        this.isLoading = false;
                    }
                })
                delete response.links;
            }
            if (response.receiptLinks) {

                response.receiptLinks.map(link => {
                    if (link.invoice) {
                        link.document = response;
                        link.invoice.receiptDocumentLink = link;
                        this.invoice.receiptDocumentLink = link
                        this.isLoading = false;
                    }
                })
            }*/

            this.$refs.dropzone.removeFile(file);
            this.$emit('documentSaved', response);
        },
        deleteDocument: function(document) {
            if (document && document.id) {
                if (confirm('Bist du sicher, dass du dieses rechnung löschen möchtest?')) {
                    this.isLoading = true;
                    axiosDelete('documents/' + document.id).then(response => {
                        this.$emit('documentDeleted', document)
                        this.computedElement = null;
                        this.isLoading = false;
                        /*if (this.element && this.property && this.element[this.property]){
                            this.element[this.property] = null;
                        }
                        this.isLoading = false;
                        location.reload();*/
                    });
                }
            }
        },
        downloadDocument: function(document) {
            if (document) {
                if(document && document.cloudStorage){
                    return document.path;
                }
                return getFilePath(document.id)
            }
        },

    },
    computed: {
        computedElement: {
            get () {
                if(this.element) {
                    if(this.elementProperty) {
                        return this.element[this.elementProperty];
                    }
                }
                if(this.elementProperty) {
                    return this.internalRow[this.elementProperty];
                }
                return this.internalRow;
            },
            set (value) {
                if(this.element) {
                    if(this.elementProperty) {
                        this.element[this.elementProperty] = value;
                    }
                } else if(this.elementProperty) {
                    this.internalRow[this.elementProperty] = value;
                } else {
                    this.internalRow = value;
                }
            },
        },

        template: function () {
            return `<div class="dz-preview dz-file-preview" style="display:none">
                        <div class="dz-details">
                            <span data-dz-name></span>
                            <div class="dz-error-message">
                                <span data-dz-errormessage></span>
                            </div>
                        </div>
                        <div class="dz-progress">
                            <span class="dz-upload" data-dz-uploadprogress></span>
                        </div>

                        <div class="dz-remove">
                            <i class="material-icons" data-dz-remove>delete</i>
                        </div>
                    </div>`;
        },
        dropzoneOptions: function () {
            let defaultOptions = {
                url: '/api/documents/upload',
                maxFilesize: 20,
                headers: authHeader(),
                previewTemplate: this.template,
                createImageThumbnails: false,
                clickable: true,
                paramName: "user_file_upload"
            }
            return {...defaultOptions, ...this.options};
        }
    },

    components: {
        vueDropzone: vue2Dropzone,
        Loading
    }
}
</script>
