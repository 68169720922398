<template>
    <div class="is-full-width">
        <template v-if="value">
            <i class="material-icons">done</i>
        </template>
        <template v-else>
            <i class="material-icons">close</i>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        value: { required: true },
    },
}
</script>
