var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.type === "date" ? "multiselect" : "is-full-width" },
    [
      _vm.type === "date"
        ? _c("multiselect", {
            ref: "select",
            attrs: {
              "allow-empty": true,
              placeholder: "Datum",
              options: _vm.listOfDays,
            },
            model: {
              value: _vm.selectedTimeDay,
              callback: function ($$v) {
                _vm.selectedTimeDay = $$v
              },
              expression: "selectedTimeDay",
            },
          })
        : [
            !_vm.selectedTimeDay
              ? _c("span", [_vm._v("Tag wählen")])
              : !_vm.time
              ? _c("span", [_vm._v("-")])
              : _vm.time
              ? _c("date-time", {
                  ref: "date",
                  attrs: { debounce: true },
                  model: {
                    value: _vm.time,
                    callback: function ($$v) {
                      _vm.time = $$v
                    },
                    expression: "time",
                  },
                })
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }