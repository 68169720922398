<template>
    <div class="is-full-width" :title="localValue">
        <div class="control is-expanded" v-if="!textOnly">
            <input
                class="input"
                type="text"
                ref="input"
                v-bind:value="localValue"
                v-on:blur="updateValue"
                v-on:focus="focus"
                v-bind:class="inputClass"
                v-bind:disabled="disabled"
                v-bind:placeholder="placeholder"
                :readonly="readOnlyComputed"
            />
        </div>
        <span v-else>{{value}}</span>
    </div>
</template>



<script>


export default {
    props: {
        value: { required: true },

        inputClass: {
            type: String,
            default: ''
        },

        placeholder: {
            type: String,
            default: ''
        },

        disabled: { type: Boolean, default: false },
        nullable: { type: Boolean, default: false },
        readOnly: { type: Boolean, default: false },
        readOnlyValue: {
            default: null,
        },
        textOnly: { type: Boolean, default: false },

    },


    computed: {
        readOnlyComputed: function () {
            return this.readOnly || (!!this.readOnlyValue && this.readOnlyValue === this.localValue)
        },
        localValue: {
            get() {
                return this.value;
            },
            set(inputValue) {
                if(this.localValue != inputValue) {
                    this.$emit('input',  inputValue);
                    this.$emit('change', inputValue);
                }
            }
        }
    },


    data: function () {
        return {
        }
    },


    methods: {
        updateValue: function (event) {
            let inputValue = event.target.value;
            if(this.localValue != inputValue) {
                this.$emit('input',  inputValue);
                this.$emit('change', inputValue);
            }
        },


        focus: function () {
            if (this.$refs.input) {
                this.$refs.input.select();
            }
        },



    },


    watch: {
        value: function () {
            if (!this.value && !this.nullable) {
                this.localValue = this.nullable ? null : '';
            }
        }
    },
}
</script>
