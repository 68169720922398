<template>
    <prism-editor class="prismCodeEditor":readonly="readonly" v-model="code" :highlight="highlighter" line-numbers></prism-editor>
</template>

<script>
// import Prism Editor
import { PrismEditor } from 'vue-prism-editor';

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from './prism';

export default {
    components: {
        PrismEditor,
    },
    props: {
        value: {},
        readonly: {}
    },
    computed: {
        code: {
            get () {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        highlighter(code) {
            return highlight(code, languages.twig); // languages.<insert language> to return html with markup
        },
    },
}
</script>
