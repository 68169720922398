var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.readOnly
    ? _c(
        "div",
        {
          staticClass: "input-price field has-addons",
          class: { "has-suffix": _vm.suffix },
          staticStyle: { width: "100%" },
          on: {
            click: _vm.focus,
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
              )
                return null
              return _vm.focus.apply(null, arguments)
            },
          },
        },
        [
          _vm.showPrefix && !_vm.suffix
            ? _c(
                "div",
                { staticClass: "control" },
                [
                  _vm.hasPrefix
                    ? _c("div", { staticClass: "button is-static" }, [
                        _vm._v(_vm._s(_vm.prefix)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._t("prefix"),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "control is-expanded" }, [
            _c("input", {
              ref: "input",
              staticClass: "input",
              class: _vm.inputClass,
              attrs: {
                type: "text",
                disabled: _vm.disabled,
                placeholder: _vm.placeholder,
                readonly: _vm.inputReadOnly,
              },
              domProps: { value: _vm.localPrice },
              on: {
                blur: _vm.updatePrice,
                focus: _vm.focus,
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.updatePrice.apply(null, arguments)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _vm.showPrefix && _vm.suffix
            ? _c(
                "div",
                { staticClass: "control" },
                [
                  _vm.hasPrefix
                    ? _c("div", { staticClass: "button is-static" }, [
                        _vm._v(_vm._s(_vm.prefix)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._t("prefix"),
                ],
                2
              )
            : _vm._e(),
        ]
      )
    : _c("span", { attrs: { "data-prefix": _vm.prefix } }, [
        _vm._v(_vm._s(_vm._f("priceView")(_vm.value))),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }