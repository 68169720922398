var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("multiselect", {
    ref: "select",
    attrs: {
      "track-by": "key",
      "custom-label": (type) =>
        type.name ? type.name : type.stars + "* " + type.category,
      options: _vm.hotelTypes,
    },
    model: {
      value: _vm.localHotelType,
      callback: function ($$v) {
        _vm.localHotelType = $$v
      },
      expression: "localHotelType",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }