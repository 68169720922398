<template>
    <div>
        <datepicker
            v-bind:config="config"
            v-model="innerValue"
            :placeholder="placeholder"
            class="flatpickr--range"
        />
    </div>
</template>

<script>
import { German } from "flatpickr/dist/l10n/de.js";
import Datepicker from 'vue-bulma-datepicker';
import { dateRange } from '@utilities/functions';
import moment from "moment/moment";

export default {
    props: {
        value: {},
        default: {
            type: Array,
        },
        placeholder: {
            type: String,
            default: 'Von - bis'
        }
    },

    data: function () {
        return {
            maskValue: this.value,
            config: {
                dateFormat: 'd.m.Y',
                mode: 'range',
                defaultDate: this.default,
                locale: {...German, rangeSeparator: ' - '},
                allowInput: true,
                parseDate:  function (dateStr) { return moment(dateStr, 'DD.MM.YYYY').toDate(); },
                formatDate: function (dateObj) { return moment(dateObj).format('DD.MM.YYYY'); },
                onClose: function (_, __, fp) {
                    const value = fp.input.value;
                    fp.setDate(dateRange(value), true, 'd.m.Y');
                },
            }
        }
    },
    computed: {
        innerValue: {
            get: function () {
                return this.value;
            },
            set: function(v) {
                this.$emit('input', v);
            }
        }
    },
    components: { Datepicker },
}
</script>
