var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "multiselect",
    {
      class: _vm.myClass,
      attrs: {
        options: _vm.optionTags,
        taggable: true,
        multiple: "",
        "custom-label": _vm.customTagLabel,
        "track-by": "id",
        placeholder: "",
        "max-height": 600,
      },
      on: { tag: _vm.setTag },
      scopedSlots: _vm._u([
        {
          key: "option",
          fn: function (props) {
            return [
              _c(
                "span",
                { staticClass: "option__name", class: props.option.class },
                [
                  _c(
                    "i",
                    {
                      staticClass: "material-icons",
                      class: props.option.class,
                    },
                    [_vm._v(_vm._s(props.option.icon))]
                  ),
                  _vm._v(" " + _vm._s(props.option.name)),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.tagSelected,
        callback: function ($$v) {
          _vm.tagSelected = $$v
        },
        expression: "tagSelected",
      },
    },
    [_vm._v(" "), _c("span", { attrs: { slot: "caret" }, slot: "caret" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }