<template>
    <vue-toggles
        :height="height"
        :width="width"
        :checkedText="checkedText"
        :uncheckedText="uncheckedText"
        :checkedBg="checkedBg"
        :uncheckedBg="uncheckedBg"
        :value="value"
        @click="input => $emit('input', !input)"
    />
</template>


<script>
import VueToggles from 'vue-toggles';

export default {
    props: {
        value: {},
        checkedText: {
            type: String,
            default: 'ok'
        },
        uncheckedText: {
            type: String,
            default: ''
        },
        checkedBg: {
            type: String,
            default: '#41b883'
        },
        uncheckedBg: {
            type: String,
            default: '#CCCCCC'
        },
        width: {
            type: String,
            default: "42",
        },
        height: {
            type: String,
            default: "20"
        }
    },

    components: {
        VueToggles
    }
}
</script>

