var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vue-toggles", {
    attrs: {
      height: _vm.height,
      width: _vm.width,
      checkedText: _vm.checkedText,
      uncheckedText: _vm.uncheckedText,
      checkedBg: _vm.checkedBg,
      uncheckedBg: _vm.uncheckedBg,
      value: _vm.value,
    },
    on: { click: (input) => _vm.$emit("input", !input) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }