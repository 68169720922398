var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "is-full-width" },
    [
      _vm.value
        ? _c(
            "popover",
            { staticClass: "is-large", attrs: { trigger: "hover" } },
            [
              _c("i", { staticClass: "material-icons" }, [_vm._v("folder")]),
              _vm._v(" "),
              _c("template", { slot: "popover" }, [
                _c(
                  "ul",
                  { staticClass: "has-text-left" },
                  _vm._l(_vm.value.requestsInfo, function (request) {
                    return _c("li", [
                      _vm._v(
                        _vm._s(request.date) +
                          ": " +
                          _vm._s(request.name) +
                          " | " +
                          _vm._s(request.status)
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }