<template>
    <multiselect
        v-model        = "tagSelected"
        v-bind:options = "templatesFiltered"
        :class          = "myClass"
        label          = "name"
        track-by       = "id"
        placeholder    = "Wählen Sie eine Vorlage"
        v-on:select    = "fillEmail"
        :max-height="400"

    >
        <template slot="option" slot-scope="props">
            <span class="option__name" :class="props.option.class"><i class="material-icons" :class="props.option.classes" v-if="props.option.icon">{{ props.option.icon }}</i> {{ props.option.name }}</span>
        </template>
        <span slot="caret" v-if="value && value.length === 0"></span>
    </multiselect>
</template>



<script>

import Multiselect from './Multiselect';


export default {
    props: {
        value:       {},
        templatesFiltered: {},
        myClass: {}
    },


    computed: {
        tagSelected: {
            get: function () { return this.value ? this.value : null },
            set: function (ordersSelected) { this.$emit('input', ordersSelected )}
        }
    },



    methods: {
        fillEmail: function () {
            this.$emit('fillEmail');
        },
    },


    components: {
        Multiselect
    }
}
</script>
