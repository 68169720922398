import Vuex from 'vuex';
import axios from 'axios';
import has from 'lodash/has';
import get from 'lodash/get';
import minBy from 'lodash/minBy';
import set from 'lodash/set';

import { dateViewtoISO, fullYear, dateISOtoView, currentUser }   from '@utilities/functions';
import {
    getAreas, getPlaces, getDestinations, optionsTodoList, editHotelContingent, editFerryContingent,
} from '@api';
import debounce from "lodash/debounce";
import {notifyError, notifySuccess} from "@components/Notification";
import quickEdit from './store_modules/quickEdit';


const updatePlaceholders = function (placeholders, { requestID, optionTodo }) {
    return placeholders.map(placeholder => {
        const requests = placeholder.requests.map(request => {
            if (request.id === requestID) {
                return Object.assign({}, request, {
                    optionTodo: {...optionTodo, ...optionTodo.doneDate && {dueDate: null}}
                });
            } else {
                return request;
            }
        });

        const minRequest = minBy(
            requests.filter(request => request.optionTodo && request.optionTodo.dueDate),
            (request => dateViewtoISO(request.optionTodo.dueDate))
        );
        const editInfo = placeholder.requests.filter(request => request.id === requestID).length > 0;

        const info = Object.assign({}, placeholder.info, {
            ...editInfo && { deadline: !!minRequest ? minRequest.optionTodo.dueDate : null }
        });

        return Object.assign({}, placeholder, {
            requests,
            info
        });
    });
};


const store = new Vuex.Store({
    modules: {
        quickEdit: quickEdit,
    },
    state: {
        orderView: {
            isEditMode:  false,
            isRouteView: false,
        },
        order:           null,
        orderID:         0,
        orderStatus:     '',
        disableMsgReader: false,

        storeRefresh: false,

        // Client
        client: {},

        // Todos
        todos: [],
        todosOptions: {
            status: [],
            type: [],
            validFor: [],
            internalNames: [],
            externalNames: [],
            predefinedTags: []
        },
        todosOptionsLoaded: false,

        orderContacts: {},
        providerContacts: [],

        // Contingent
        participants:            {},
        airlineContingent:       [],
        ferryContingent:         [],
        hotelContingent:         [],
        optionsRoomTypes:        [
            { key: 'standard', value: 'Standard' },
            { key: 'deluxe',   value: 'Deluxe' },
            { key: 'sea_view',  value: 'Meerblick' }
        ],

        // TODO - to be checked
        optionsHotelTypes:       [
            { key: { stars:'2', category: '' },  value: '2* Hotel' },
            { key: { stars:'3', category: '' },  value: '3* Hotel' },
            { key: { stars:'4', category: '' },  value: '4* Hotel' },
            { key: { stars:'5', category: '' },  value: '5* Hotel' },
            { key: { stars:'4', category: 'superior' },  value: 'Superior 4* Hotel' },
            { key: { stars:'5', category: 'superior' },  value: 'Superior 5* Hotel' },

        ],
        optionsCabinTypes:       [
            { key: 'inside',  value: 'Innen' },
            { key: 'outside', value: 'Außen' }
        ],
        optionsTrainCabinTypes:       [
            { key: 'second',  value: '2. Klasse' },
            { key: 'first', value: '1. Klasse' }
        ],
        optionsFerryRouteTypes:  [
            { key: 'day',   value: 'Tag' },
            { key: 'night', value: 'Nacht' }
        ],
        optionsTrainRouteTypes:  [
            { key: 'day',   value: 'Tag' },
            { key: 'night', value: 'Nacht' }
        ],
        optionsTrainTypes:  [
            { key: 'standard',   value: 'Standard' },
            { key: 'panorama', value: 'Panorama' }
        ],
        optionsLocationCharges: {
            types: []
        },
        optionsProviderContacts: {},

        // Dashboad
        optionsUsers:            [],

        // We need the categories when saving Day Concepts or saving Days from Concepts
        optionsDayConcept:       {
            categories: []
        },

        // Options for Other Services
        otherServiceTypeList:    [],

        // General options
        options: {},

        // Loading by sections
        isLoading: {
            global:        false,
            dashboard:     false,
            modal:         false,
            modalRequest:  false,
            requestSaving: false,
            todos:         false,
            contacts:      false,
        },

        // Modal states
        modal: {
            toRequest: 0,
            toNewRequest: false
        },

        providerItemPrices: {},


        placeholdersSelected: [],
        requestsSelected: []
    },


    mutations: {
        updateStoreRefresh:  function (state, newMode) { state.storeRefresh  = newMode; },

        // Order View
        updateEditMode:  function (state, newMode) { state.orderView.isEditMode  = newMode; },
        updateRouteView: function (state, newView) { state.orderView.isRouteView = newView; },

        updateOrder:     function (state, newOrder) {
            state.order   = Object.assign({}, state.order, newOrder);
            if (newOrder.id) {
                state.orderID = newOrder.id;
            }
        },

        updateOrderTrip:   function (state, newTrip)   { state.order.trip  = Object.assign({}, state.order.trip, newTrip); },
        updateOrderID:     function (state, newID)     { state.orderID     = newID; },
        updateOrderStatus: function (state, newStatus) { state.orderStatus = newStatus; },
        updateDisableMsgReader: function (state, newValue) { state.disableMsgReader = newValue},


        /* =============================== Days ============================================== */

        updateDay: function (state, { id, properties }) {
            const index = state.order.days.findIndex(x => x.id === id);

            state.order.days.splice(index, 1, Object.assign({}, state.order.days[index], properties));
        },


        addDay:    function (state, day)   { state.order.days.push(day); },
        deleteDay: function (state, dayID) { state.order.days = state.order.days.filter(x => x.id !== dayID); },

        /* =================================== end of Days =================================== */


        /* ================================== Placeholders =================================== */

        addPlaceholder: function (state, { dayID, placeholderType, data }) {
            const index = state.order.days.findIndex(x => x.id === dayID);

            state.order.days[index][placeholderType].push(data);
        },

        updatePlaceholder: function (state, { dayID, placeholderType, placeholderID, placeholderData, incremental = false }) {
            if(!dayID) {
                for (const day of state.order.days) {
                    if(day[placeholderType].findIndex(x => Number(x.id) === Number(placeholderID)) !== -1) {
                        dayID = day.id;
                        break;
                    }
                }
            }
            const dayIndex         = state.order.days.findIndex(x => Number(x.id) === Number(dayID));
            const placeholderIndex = state.order.days[dayIndex][placeholderType].findIndex(x => {
                return x.id && placeholderID && Number(x.id) === Number(placeholderID)
            });

            if(incremental) {
                placeholderData= {...state.order.days[dayIndex][placeholderType][placeholderIndex], ...placeholderData}
            }
            state.order.days[dayIndex][placeholderType].splice(placeholderIndex, 1, placeholderData);

        },

        updateRequest: function (state, {dayID, placeholderType, placeholderID, requestID, requestData, incremental = false}) {
            if(!dayID) {
                for (const day of state.order.days) {
                    if(day[placeholderType].findIndex(x => Number(x.id) === Number(placeholderID)) !== -1) {
                        dayID = day.id;
                        break;
                    }
                }
            }
            const dayIndex = state.order.days.findIndex(x => x.id === dayID);
            const placeholderIndex = state.order.days[dayIndex][placeholderType].findIndex(x => Number(x.id) === Number(placeholderID));
            const requestIndex = state.order.days[dayIndex][placeholderType][placeholderIndex].requests.findIndex(x => Number(x.id) === Number(requestID));
            if(incremental) {
                requestData = {...state.order.days[dayIndex][placeholderType][placeholderIndex].requests[requestIndex], ...requestData};
            }
            state.order.days[dayIndex][placeholderType][placeholderIndex].requests.splice(requestIndex, 1, requestData);
        },


        deletePlaceholder: function (state, { dayID, placeholderType, placeholderID }) {
            const dayIndex         = state.order.days.findIndex(x => x.id === dayID);
            const placeholderIndex = state.order.days[dayIndex][placeholderType].findIndex(x => x.id === placeholderID);

            state.order.days[dayIndex][placeholderType].splice(placeholderIndex, 1);
        },


        addRequest: function (state, { dayID, placeholderType, placeholderID, request }) {
            const dayIndex         = state.order.days.findIndex(x => x.id === dayID);
            const placeholderIndex = state.order.days[dayIndex][placeholderType].findIndex(x => x.id === placeholderID);

            state.order.days[dayIndex][placeholderType][placeholderIndex].requests.push(request);
        },

        /* =============================== end of Placeholders =============================== */


        // TOOD - to be removed and replaced with a component. We have 200+ item in this list
        updateOtherServiceTypeList: function (state, newServicesType) { state.otherServiceTypeList  = newServicesType; },


        updateClient:               function (state, newClient)       { state.client = newClient; },
        updateOrderContacts:        function (state, newContacts)     { state.orderContacts = newContacts; },
        updateProviderContacts:     function (state, newContacts)     { state.providerContacts = newContacts; },
        updateOrderContact:         function (state, newContact)      {
            if (has(newContact, 'order')) { // Order contact update
                state.orderContacts = state.orderContacts.map(orderContact => ({
                    ...orderContact,
                    ...(!orderContact.id || orderContact.id === newContact.id) && {...newContact}
                }));
            } else { // Contact update
                state.orderContacts = state.orderContacts.map(orderContact => ({
                    ...orderContact,
                    ...orderContact.contact && {
                        contact: {
                            ...orderContact.contact,
                            ...orderContact.contact.id === newContact.id && newContact
                        }
                    }
                }));
            }
        },
        addOrderContact:            function (state, newContact)      { state.orderContacts = [...state.orderContacts, newContact]; },
        deleteOrderContact:         function (state, contact)         { state.orderContacts = state.orderContacts.filter(({ id }) => contact.id !== id); },

        /* =============================== ------------------ =============================== */

        updateParticipants:    function (state, newContingent) { state.participants  = newContingent; },
        updateFerryContingent: function (state, newContingent) {
            state.ferryContingent       = newContingent;
            state.order.ferryContingent = newContingent;
        },
        updateHotelContingent: function (state, newContingent) {
            state.hotelContingent       = newContingent;
            state.order.hotelContingent = newContingent;
        },
        updateOptionsRoomTypes:  function (state, newRoomTypes)  { state.optionsRoomTypes  = newRoomTypes; },
        updateOptionsCabinTypes: function (state, newCabinTypes) { state.optionsCabinTypes = newCabinTypes; },

        /* =============================== ------------------ =============================== */

        updateOptionsLocationCharges: function(state, newOptions) { state.optionsLocationCharges = newOptions },
        updateOptionsUsers: function (state, newUsers) { state.optionsUsers = newUsers; },

        updateOptionsDayConceptCategories: function (state, newOptions) {
            let colors     = ['red', 'blue-dark', 'green', 'blue', 'yellow', 'purple', 'brown', 'orange'];

            state.optionsDayConcept.categories = newOptions.map((x, index) => {
                x.color = colors[index];
                return x;
            });
        },

        updateOptions: function (state, newOptions) { state.options = Object.assign({}, state.options, newOptions); },
        updateRequestOptions: function (state, { requestId, key, newOptions }) {
            const options = Object.assign({}, state.options);
            set(options, [requestId, key], newOptions);
            state.options = options;
        },
        updateOptionsProviderContacts: function (state, {id, options}) {
            state.optionsProviderContacts[id] = options;
        },

        // Loadings
        updateIsLoadingDashboard:    function (state, newState)   { state.isLoading.dashboard     = newState; },
        updateIsLoadingModal:        function (state, newState)   { state.isLoading.modal         = newState; },
        updateIsLoadingModalRequest: function (state, newState)   { state.isLoading.modalRequest  = newState; },
        updateGlobalLoading:         function (state, newState)   { state.isLoading.global        = newState; },
        updateTodosLoading:          function (state, newState)   { state.isLoading.todos         = newState; },
        updateContactsLoading:          function (state, newState)   { state.isLoading.contacts         = newState; },
        updateRequestSaving:         function (state, newState)   { state.isLoading.requestSaving = newState; },


        // Modal States
        updateModalToNewRequest: function (state, newState) { state.modal.toNewRequest = newState; },
        updateModalToRequest:    function (state, newState) { state.modal.toRequest    = newState; },

        // Deadlines (todos)
        updateTodosOptions: function (state, options) {
            state.todosOptions = Object.assign({}, state.todosOptions, options);
        },
        updateTodosOptionsLoaded: function (state, newState) {
            state.todosOptionsLoaded = true;
        },

        updateTodos: function (state, todos) {
            state.todos = todos;
        },
        addTodo: function (state, todo) {
            state.todos = [...state.todos, todo];
        },
        updateTodo: function (state, todo) {
            const foundIndex = state.todos.findIndex(item => item.id === todo.id);
            if(foundIndex === -1){
                todo.justSaved = false;
            }
            state.todos.splice(foundIndex, 1, todo);

            if (state.order && state.order.orderTodo && state.order.orderTodo.id === todo.id) {
                state.order.orderTodo = JSON.parse(JSON.stringify(todo))
            }
        },
        storeTodo: function (state, newTodo) {
            const todos = JSON.parse(JSON.stringify(state.todos))
                .filter(todo => todo.id !== newTodo.id);
            state.todos = [...todos, newTodo];
        },

        addProviderItemPrices: function (state, prices) {
            state.providerItemPrices = Object.assign({}, state.providerItemPrices, prices);
        },

        // Placeholder update in days
        updateRequestOptionTodo: function (state, request) {
            state.order = {
                ...state.order,
                days: get(state, 'order.days', []).map(day => Object.assign({}, day, {
                    airlinePlaceholders: updatePlaceholders(day.airlinePlaceholders, request),
                    ferryPlaceholders:   updatePlaceholders(day.ferryPlaceholders, request),
                    trainPlaceholders:   updatePlaceholders(day.trainPlaceholders, request),
                    hotelPlaceholders:   updatePlaceholders(day.hotelPlaceholders, request),
                    otherPlaceholders:   updatePlaceholders(day.otherPlaceholders, request)
                }))
            }
        },


        updatePlaceholdersSelected: function (state, object) {
            if (state.requestsSelected.length === 0) {
                const index = state.placeholdersSelected.findIndex(placeholder =>
                    (placeholder.info.type === object.placeholder.info.type &&
                        placeholder.id === object.placeholder.id));

                if (object.event && object.event.shiftKey) {
                    // Multi email select

                    if (index !== -1) {
                        // Deselecting one
                        state.placeholdersSelected.splice(index, 1);
                    } else {
                        // Adding multiselect - at the begging of the array
                        state.placeholdersSelected.unshift(object.placeholder);
                    }
                } else {
                    // Simple click
                    if (index !== -1) {
                        state.placeholdersSelected.splice(index, 1);
                    } else {
                        state.placeholdersSelected = [object.placeholder];
                    }
                }
            }
        },

        selectRequest: function (state, request) {
            if (state.placeholdersSelected.length === 0) {
                if (state.requestsSelected.some(({ id }) => id === request.id)) {
                    state.requestsSelected = state.requestsSelected
                        .filter(({ id }) => id !== request.id);
                } else {
                    state.requestsSelected = [
                        ...state.requestsSelected,
                        JSON.parse(JSON.stringify(request))
                    ];
                }
            }
        },

        selectRequests: function (state, requests) {
            if (state.placeholdersSelected.length === 0) {
                state.requestsSelected = JSON.parse(JSON.stringify(requests));
            }
        },

        clearSelection: function (state) {
            state.requestsSelected = [];
            state.placeholdersSelected = [];
        }
    },


    actions: {
        fetchLocations: function ({ commit, state }, { query, isLimited = false }) {
            let paramsAreas  = '?_search=' + query,
                paramsPlaces = '?_search=' + query;

            if (isLimited && state.order.trip.destinations.length > 0) {
                paramsAreas  += '&destination.id[]=' + state.order.trip.destinations
                    .map(item => item.id).join('&destination.id[]=');

                paramsPlaces += '&area.destination.id[]=' + state.order.trip.destinations
                    .map(item => item.id).join('&area.destination.id[]=');
            }

            return Promise.all([
                getAreas(paramsAreas),
                getPlaces(paramsPlaces),
                getDestinations('?_search=' + query)
            ]).then(([areas, places, destinations]) => (
                {
                    areas:        areas.data,
                    places:       places.data,
                    destinations: destinations.data
                }
            ));
        },


        fetchTodosOptions: function ({ commit, state }) {
            if (!state.todosOptionsLoaded) {
                commit('updateTodosOptionsLoaded', true);
                optionsTodoList().then(response => {
                    commit('updateTodosOptions', response.data);
                });
            }
        },

        changeRequestStatus: function ({ commit, dispatch }, { request, status }) {
            let placeholderType = request.placeholder.split('/')[2],
                api = '/api/' + placeholderType.replace('_placeholders', '_requests/') + request.id + '?_groups[]=modal_write';

            return new Promise((resolve, reject) => {
                axios.put(api, {
                    requestStatus: {
                        createdBy: request.requestStatus.createdBy,
                        status
                    },
                    ...status === 'canceled' && {
                        optionTodo: {
                            ...request.optionTodo,
                            dueDate: null
                        }
                    }
                }).then(() => {
                    dispatch('updatePlaceholder', request.placeholder)
                        .then((response) => resolve(response.data), error => reject(error));
                }, error => reject(error));
            });
        },

        editHotelContingent: debounce(function ({ commit, dispatch }, {hotelContingent}) {

            editHotelContingent({
                id: hotelContingent.id,
                data: hotelContingent
                })
                    .then(response => {
                        commit('updateHotelContingent', response.data)
                        notifySuccess('Kontingent aktualisiert!');

                    }, error => { notifyError('Das konnte nicht aktualisiert werden! Server Error!'); })
                    .then(() => { this.isLoading = false; });
        },900),


    editFerryContingent: debounce(function ({ commit, dispatch }, {ferryContingent}) {
        editFerryContingent({
            id: ferryContingent.id,
            data: ferryContingent
            })
            .then(response => {
                commit('updateFerryContingent', response.data);
                notifySuccess('Kontingent aktualisiert!');

                }, error => { notifyError('Das konnte nicht aktualisiert werden! Server Error!'); })
            .then(() => { this.isLoading = false; });
    },100),



    changeOptionTodo: function ({ commit, dispatch }, { request, deadline }) {
        let placeholderType = request.placeholder.split('/')[2],
            api = '/api/' + placeholderType.replace('_placeholders', '_requests/') + request.id + '?_groups[]=modal_write';
        const isDone = !deadline;

            const optionTodo = {
                ...!!request.optionTodo && request.optionTodo.id && {id: request.optionTodo.id},
                dueDate: isDone ? null : `${fullYear(deadline)} 00:00:00`,
                doneDate: isDone ? dateISOtoView((new Date()).toISOString()) + '00:00:00' : null,
                user: `/api/users/${currentUser('id')}`,
                status: isDone ? 'done' : 'open',
            }

            return new Promise((resolve, reject) => {
                axios.put(api, {
                    optionTodo: optionTodo
                }).then(response => {
                    response.data.optionTodo.justSaved = true;
                    commit('updateTodo', response.data.optionTodo);
                    dispatch('updatePlaceholder', request.placeholder)
                        .then((response) => resolve(response.data), error => reject(error));
                }, error => reject(error));
            });
        },

        updatePlaceholder({ commit }, placeholder) {
            const placeholderType = placeholder.split('/')[2]

            return new Promise((resolve, reject) => {
                axios.get(placeholder, {
                    params: {
                        _groups: ['order_request_todo_read', 'todo_read', 'modal_read', 'info_read']
                    }
                }).then(response => {
                    commit('updatePlaceholder', {
                        dayID:           response.data.day.id ? response.data.day.id : parseInt(response.data.day.split('/')[3], 10),
                        placeholderType: placeholderType.replace('_placeholders', 'Placeholders'),
                        placeholderID:   response.data.id,
                        placeholderData: response.data
                    });
                    resolve(response.data);
                }, error => reject(error));
            });
        },
    },

    getters: {
        requests: function (state) {
            return [].concat(...[].concat(...state.order.days
                .map(({ hotelPlaceholders, ferryPlaceholders, trainPlaceholders, airlinePlaceholders, otherPlaceholders }) => [
                    ...hotelPlaceholders.map(({ requests }) => requests),
                    ...ferryPlaceholders.map(({ requests }) => requests),
                    ...trainPlaceholders.map(({ requests }) => requests),
                    ...airlinePlaceholders.map(({ requests }) => requests),
                    ...otherPlaceholders.map(({ requests }) => requests),
                ])))
        },

        order: function(state) {
            return state.order;
        }


    }
});


export { store };
export default store;
