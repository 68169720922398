var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "multiselect" },
    [
      _c("multiselect", {
        ref: "select",
        attrs: {
          options: _vm.agency
            ? this.agencyContactOptions
            : this.providerContactOptions,
          "track-by": "id",
          "custom-label": (item) => `${item.fullName}`,
          "to-be-copied": false,
        },
        on: { open: _vm.checkParamsChange },
        model: {
          value: _vm.contact,
          callback: function ($$v) {
            _vm.contact = $$v
          },
          expression: "contact",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }