<template>
    <div :class="type === 'date' ? 'multiselect' : 'is-full-width'">
        <multiselect
            ref="select"
            v-if="type === 'date'"
            v-model="selectedTimeDay"
            :allow-empty="true"
            placeholder="Datum"
            v-bind:options="listOfDays"
        ></multiselect>
        <template v-else>
            <span v-if="!selectedTimeDay">Tag wählen</span>
            <span v-else-if="!time">-</span>
            <date-time ref="date" :debounce="true" v-else-if="time" v-model="time"></date-time>
        </template>
    </div>




</template>

<script>

import { store } from './store';
import Multiselect from "@components/form/Multiselect";
import {addDays, listOfDaysWithoutTime} from "@utilities/functions";
import DateTime from "@components/form/DateTime";
import moment from "moment";


export default {
    store,

    props: {
        board: {required: true, type: String},
        requestId: {required: true, type: Number},
        startAt: {required: true, type: String},
        endAt: {required: true, type: String},
        value: { required: true, type: Array }, //should be the times object
        type: {type: String, required: true}, //type of time or date
    },


    computed: {
        listOfDays: function () {
            return listOfDaysWithoutTime(this.startAt, this.endAt);
        },

        selectedTimeDay: {
            get: function() {
               let time = this.$store.state.hotelTimes.find(item => item.requestId === this.requestId);
               return time ? time.date : null;
            },
            set: function(value) {
                let times = this.$store.state.hotelTimes;
                let time = times.find(item => item.requestId === this.requestId);
                if(!time) {
                    times.push({requestId: this.requestId, date: value});
                } else {
                    time.date = value;
                }
                this.$store.commit('updateState', {property: 'selectedFoodDay', value: times})
            }
        },

        time: {
            get: function () {
                if(!this.selectedTimeDay) {
                    return null;
                }
                let times = this.value;
                let time = times.find(item =>
                    item.type === this.type && moment(item.time, 'DD.MM.YYYY').format('DD.MM.YYYY') === moment(this.selectedTimeDay, 'DD.MM.YYYY').format('DD.MM.YYYY')
                );
                if(!time && this.allowedTimes.includes(this.type)) {
                    return moment(this.selectedTimeDay, 'DD.MM.YYYY').format('DD.MM.YYYY') + '00.00.00'
                }
                return time ? time.time : null;
            },
            set: function (value) {
                if(!value) {
                    return;
                }
                let times = JSON.parse(JSON.stringify(this.value));

                let time = times.find(item =>
                    item.type === this.type && moment(item.time, 'DD.MM.YYYY').format('DD.MM.YYYY') === moment(this.selectedTimeDay, 'DD.MM.YYYY').format('DD.MM.YYYY')
                );
                if(time) {
                    time.time = value;

                } else if(this.allowedTimes.includes(this.type)) {
                    times.push({
                        time: value,
                        type: this.type
                    })
                } else {
                    return;
                }
                this.$emit('input', times);
            }
        },

        allowedTimes: function () {
            let isFirstDay = moment(this.selectedTimeDay, 'DD.MM.YYYY').format('DD.MM.YYYY') === moment(this.startAt, 'DD.MM.YYYY').format('DD.MM.YYYY');
            let isLastDay = moment(this.selectedTimeDay, 'DD.MM.YYYY').format('DD.MM.YYYY') === moment(this.endAt, 'DD.MM.YYYY').format('DD.MM.YYYY');
            let allowedTimes = [];
            if(isFirstDay) {
                allowedTimes.push('checkIn')
            }
            if(this.board !== 'none' && !isFirstDay) {
                allowedTimes.push('breakfast');
            }
            if(['full', 'full_extra_breakfast'].includes(this.board) && !isFirstDay && !isLastDay) {
                allowedTimes.push('lunch');
            }
            if(['full', 'full_extra_breakfast', 'half', 'half_extra_breakfast'].includes(this.board) && !isLastDay) {
                allowedTimes.push('dinner');
            }
            return allowedTimes;
        }
    },

    methods: {
        focus: function () {
            if(this.$refs.select) {
                this.$refs.select.activate();
            }
            if(this.$refs.date) {
                this.$refs.date.focus();

            }
        }
    },


    components: {
        DateTime,
        Multiselect
    }


}
</script>
