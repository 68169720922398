var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("prism-editor", {
    staticClass: "prismCodeEditor",
    attrs: {
      readonly: _vm.readonly,
      highlight: _vm.highlighter,
      "line-numbers": "",
    },
    model: {
      value: _vm.code,
      callback: function ($$v) {
        _vm.code = $$v
      },
      expression: "code",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }