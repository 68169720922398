<template>
    <div class="input-price field has-addons is-full-width" v-on:click="focus" v-on:keyup.tab="focus" v-if="!readOnly && !package" :class="{'has-suffix': suffix}">
        <div class="control" v-if="showPrefix && !suffix">
            <div class="button is-static" v-if="hasPrefix">{{ internalPrefix }}</div>
            <slot name="prefix"></slot>
        </div>
        <div class="control is-expanded">
            <input
                class="input"
                type="text"
                ref="input"
                v-bind:value="localPrice"
                v-on:blur="updatePrice"
                v-on:focus="focus"
                v-bind:class="inputClass"
                v-bind:disabled="disabled || package"
                v-bind:placeholder="placeholder"
                :readonly="inputReadOnly"
            />
        </div>
        <div class="control" v-if="showPrefix && suffix">
            <div class="button is-static" v-if="hasPrefix">{{ internalPrefix }}</div>
            <slot name="prefix"></slot>
        </div>
    </div>
    <span :data-prefix="!package ? internalPrefix : ''" v-else><template v-if="package">im Paket</template><template v-else>{{localPrice | priceView}}</template></span>
</template>



<script>
import { priceView } from '@utilities/functions';


export default {
    props: {
        value: { required: true },

        prefix: {
            type: String,
            default: "€"
        },

        isPrefixKey: {
            type: Boolean,
            default: false,
        },

        intrestedType: {
            type: String,
        },

        fieldName: {
            type: String,
        },

        suffix: {
            type: Boolean,
            default: false,
        },

        inputClass: {
            type: String,
            default: ''
        },

        placeholder: {
            type: String,
            default: ''
        },

        digits: {
            default: 2
        },

        package: {

        },

        disabled: { type: Boolean, default: false },
        nullable: { type: Boolean, default: false },

        readOnly: { type: Boolean, default: false},

        inputReadOnly: { type: Boolean, default: false},
        type: { type: Object, required: false },
        property: { type: String, required: true },
        returnType: {
            type: String,
            default: 'string',
        },
    },


    computed: {
        transformedValue: function () {
            if(this.internalValue === '') {
                return ''
            }
            return parseFloat(String(this.internalValue).replace(',', '.')).toFixed(this.digits)
        },
        showPrefix: function () { return !!this.$slots.prefix || this.hasPrefix; },
        hasPrefix:  function () { return !!this.prefix },
        internalValue: function() {
            if (!this.isFocused) {
                let value = {};
                const keyArray = this.property.split('.');
                if(this.type) {
                    value = this.value ? this.value.all && Array.isArray(this.value.all) && this.value[this.fieldName].find(room => room.type.type === this.type.type && room.type.persons === this.type.persons) || {} : {};
                    return this.getObjectValue(value, 0, keyArray, keyArray.length) || 0
                }
                else {
                    value = this.value
                    return this.getObjectValue(value, 0, keyArray, keyArray.length) || 0
                }
            }
            return ''
        },
        internalPrefix: function () {
            if(this.isPrefixKey) {
                const keyArray = this.prefix.split('.');
                return this.getObjectValue(this.value, 0, keyArray, keyArray.length) || "€"
            }
            return this.prefix
        },
    },

    data: function () {
        return {
            localPrice: this.fromAppToView(this.internalValue, this.digits),
            isFocused: false,
        }
    },

    methods: {
        updatePrice: function (event) {
            let price = event.target.value;

            if (this.fromViewToApp(price, this.digits) !== this.transformedValue) {
                this.localPrice =  this.fromViewToView(price, this.digits);
                if(this.nullable && this.localPrice === '') {
                    this.localPrice = null;
                }

                if (!(this.internalValue === "Infinity" && price === "∞")) {
                    if (isNaN(this.fromViewToApp(this.localPrice))) {
                        if (this.nullable) {
                            this.$emit('input', null);
                            return;

                        } else {
                            this.localPrice = '0.00';
                        }
                    }

                    const inputPrice = this.fromViewToApp(this.localPrice);
                    let updatedValue = { };
                    updatedValue.id = this.value.id;
                    if(this.type) {
                        updatedValue[this.fieldName] = this.value[this.fieldName]
                        let updatedRoom = updatedValue[this.fieldName]
                        if(!updatedRoom.find(room => room.type.type === this.type.type && room.type.persons === this.type.persons)) {
                            updatedRoom.push({ type: {...this.type}})
                        }
                        const requiredRoom = updatedRoom.find(room => room.type.type === this.type.type && room.type.persons === this.type.persons)
                        if(requiredRoom) {
                            const keyArray = this.property.split('.')
                            this.updateObject(requiredRoom, inputPrice, 0, keyArray, keyArray.length)
                        }
                        this.$emit('input',  {...this.value, ...updatedValue});
                        this.$emit('change', {...this.value, ...updatedValue});
                    }
                    else {
                        const keyArray = this.property.split('.')
                        updatedValue[keyArray[0]] = this.value[keyArray[0]]
                        this.updateObject(updatedValue, inputPrice, 0, keyArray, keyArray.length)
                        this.$emit('input',  {...this.value, ...updatedValue});
                        this.$emit('change', {...this.value, ...updatedValue});
                    }
                }
            }
            this.isFocused = false;
        },

        getObjectValue: function (obj = {}, index, keyArray, arrLen) {
            if(index === arrLen - 1) {
                return obj[keyArray[index]]
            }
            return this.getObjectValue(obj[keyArray[index]], index+1, keyArray, arrLen)
        },

        updateObject: function(prevObj, newVal, index, keyArray, arrLen) {
            if(index === arrLen - 1) {
                prevObj[keyArray[index]] = this.returnType === 'string'? newVal: parseInt(newVal);
                return;
            }
            if(!prevObj[keyArray[index]]) {
                prevObj[keyArray[index]] = {}
            }
            this.updateObject(prevObj[keyArray[index]], newVal, index+1, keyArray, arrLen)
        },

        focus: function () {
            if (this.$refs.input) {
                this.$refs.input.select();
                this.isFocused = true;
            }
        },


        // '10000.23' => '10.000,23' - german way
        fromAppToView: function (price, digits) {
            return priceView(price, digits);
        },


        // '10000,2' => '10.000,20' - german way
        fromViewToView: function (price, digits = 2) {
            let number = price.replace('.', '').replace(',', '.');
            return priceView(number, digits);
        },


        // '10.000,20' => '10000.20' - app standard
        fromViewToApp: function (price) {
            if(!price){
                return price;
            }
            let split    = price.split(','),
                decimals = split[1],
                units    = split[0];

            // Removing thousands sign
            units = units.replace('.', '');

            return units + (decimals ? '.' + decimals : '');
        }
    },


    watch: {
        internalValue: {
            immediate: true,
            handler: function () {
                if (this.internalValue === null) {
                    this.localPrice = this.nullable ? null : '0.00';

                } else {
                    if (this.transformedValue !== this.fromViewToApp(this.localPrice)) {
                        this.localPrice = this.fromAppToView(this.transformedValue, this.digits);
                    }

                    if (document.activeElement === this.$refs.input) {
                        this.$nextTick(() => {
                            this.focus();
                        });
                    }
                }
            }
        }
    },

    filters: {
        priceView
    }
}
</script>
