var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "is-full-width" },
    [
      _vm.value
        ? [_c("i", { staticClass: "material-icons" }, [_vm._v("done")])]
        : [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }