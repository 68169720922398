<template>
    <transition name="fade">
        <div v-bind:class="['modal', classes]" v-show="isVisible">
            <div class="modal-overlay"></div>

            <div class="modal-content">
                <header>
                    <slot name="header"></slot>
                    <button class="close" v-on:click.prevent.stop="$emit('closeModal')" title="Schließen"><i class="material-icons">close</i></button>
                </header>

                <div class="modal-body">
                    <slot name="content">Ana are mere</slot>
                </div>

                <footer style="display: block">
                    <slot name="footer"></slot>
                </footer>
            </div>
        </div>
    </transition>
</template>



<script>
export default {
    props: {
        classes: { type: String,  default:  '' },
        visible: { type: Boolean, default: false }
    },


    data: function () {
        return {
            isVisible: this.visible,
        }
    },


    watch: {
        visible: function (newValue, oldValue) {
            this.isVisible = newValue;
        }
    },


    mounted: function () {
        this.$on('openModal', function () {
            this.isVisible = true;
        });
    }
}
</script>
