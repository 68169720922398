<template>
    <multiselect
        ref="select"
        v-model="localHotelType"
        track-by="key"
        :custom-label="type => type.name ? type.name : type.stars + '* ' + type.category"
        v-bind:options="hotelTypes"
    >

    </multiselect>

</template>



<script>
import axios from 'axios';
import Multiselect from "@components/form/Multiselect";


export default {
    props: {
        value: { required: true }
    },


    computed: {
        localHotelType: {
            get: function () { return {...this.value, ...this.value && {key: this.value.stars + '_' + this.value.category } } },
            set: function (type) { this.$emit('input', type); }
        }
    },


    data: function () {
        return  {
            hotelTypes: []
        }
    },


    methods: {
        focus: function () {
            this.$refs.select.activate();
        },
        initialValue: function () {
            if (this.value === null) {
                this.localHotelType = this.hotelTypes[2];
            }
        }
    },


    created: function () {
        let options = JSON.parse(localStorage.getItem('options'));

        if (options && options.hotel && options.hotel.hotelTypes) {
            this.hotelTypes = options.hotel.hotelTypes.map(type => ({
                ...type,
                key: type.stars + '_' + type.category,
            }));
            this.initialValue();

        } else {
            axios.options('/api/hotels').then(response => {
                if (!options)       { options       = {}; }
                if (!options.hotel) { options.hotel = {}; }

                options.hotel.hotelTypes = response.data.hotelTypes.map(type => ({
                        category: type.category,
                        stars:    type.stars,
                        name: type.name,
                        key: type.stars + '_' + type.category,
                    }));

                this.hotelTypes = options.hotel.hotelTypes;
                localStorage.setItem('options', JSON.stringify(options));
                this.initialValue();
            });
        }
    },

    components: {
        Multiselect
    }
}
</script>
