import ColorSwitch       from './ColorSwitch';
import DateRange         from './DateRange';
import DateTime          from './DateTime';
import EmailTemplateSelect      from './EmailTemplateSelect';
import EmailSelect       from './EmailSelect';
import EntitySelect      from './EntitySelect';
import ErrorWrapper      from './ErrorWrapper';
import HotelType         from "@orders/placeholders/components/forms/HotelType";
import HotelTime          from "./HotelTime";
import ImageUpload       from './ImageUpload';
import ImageMap          from './ImageMap';
import ImageSelect       from './ImageSelect';
import InputColor        from './InputColor';
import InputCheckNumber  from './InputCheckNumber'
import InputDate         from './InputDate';
import InputMonth        from './InputMonth';
import InputPrice        from './InputPrice';
import InputContingentPrice   from './InputContingentPrice';
import Multiselect       from './Multiselect';
import OrganisationSelect   from './OrganisationSelect';
import OrganisationBankAccountSelect from "./OrganisationBankAccountSelect";
import TimeOfDaySelect   from './TimeOfDaySelect';
import TinyMce           from './TinyMce';
import TipTap            from './TipTap';
import Prism             from './Prism';
import UserSelect        from './UserSelect';
import PlaceSelect       from './PlaceSelect';
import AreaSelect        from './AreaSelect';
import DestinationSelect from './DestinationSelect';
import AsyncSelect       from './AsyncSelect';
import TagSelect         from './TagSelect';
import ToggleSwitch      from './ToggleSwitch';
import Toggle            from './Toggle';
import OrderSelect       from './OrderSelect';
import LocationSelect    from './LocationSelect';
import InputPlace        from './InputPlace';
import MonthSelect       from './MonthSelect';
import ListSelect        from './ListSelect';
import Resizable         from './Resizable';
import RequestContact    from './RequestContact'
import Switch            from './Switch';
import TextInput         from './TextInput';
import Document         from './Document';
import InputDateTable    from './InputDateTable';
import InputContingentDataTable    from './InputContingentDataTable';
import PackageIcon       from './PackageIcon';
import PureText          from './PureText';
import Boolean          from './Boolean';

import TodoForm          from './Todo';
import Translations      from './Translations';



export {
    ColorSwitch,
    DateRange,
    DateTime,
    EmailTemplateSelect,
    EmailSelect,
    EntitySelect,
    ErrorWrapper,
    HotelType,
    ImageUpload,
    ImageSelect,
    ImageMap,
    InputCheckNumber,
    InputColor,
    InputDate,
    InputDateTable,
    InputMonth,
    InputPlace,
    InputPrice,
    InputContingentPrice,
    Multiselect,
    OrganisationSelect,
    OrganisationBankAccountSelect,
    TimeOfDaySelect,
    TinyMce,
    TipTap,
    TagSelect,
    UserSelect,
    PlaceSelect,
    Prism,
    AreaSelect,
    DestinationSelect,
    AsyncSelect,
    ToggleSwitch,
    Toggle,
    OrderSelect,
    LocationSelect,
    MonthSelect,
    ListSelect,
    Resizable,
    Switch,
    TextInput,
    Document,
    PackageIcon,
    PureText,
    TodoForm,
    Translations,
    Boolean
};

export default {
    DateRange,
    DateTime,
    EmailTemplateSelect,
    EmailSelect,
    EntitySelect,
    ErrorWrapper,
    HotelTime,
    HotelType,
    ImageUpload,
    ImageSelect,
    ImageMap,
    InputCheckNumber,
    InputColor,
    InputContingentDataTable,
    InputDate,
    InputDateTable,
    InputMonth,
    InputPlace,
    InputPrice,
    InputContingentPrice,
    Multiselect,
    OrganisationSelect,
    OrganisationBankAccountSelect,
    TimeOfDaySelect,
    TinyMce,
    TipTap,
    TagSelect,
    UserSelect,
    Prism,
    PlaceSelect,
    AreaSelect,
    DestinationSelect,
    AsyncSelect,
    ToggleSwitch,
    Toggle,
    OrderSelect,
    LocationSelect,
    MonthSelect,
    ListSelect,
    Resizable,
    RequestContact,
    TextInput,
    Document,
    PackageIcon,
    PureText,
    Boolean,
    TodoForm,
    Translations,
};
