<template>
    <div class="multiselect">
        <multiselect
            ref="select"
            v-model="contact"
            v-bind:options="agency ? this.agencyContactOptions : this.providerContactOptions"
            track-by="id"
            :custom-label="item => `${item.fullName}`"
            :to-be-copied="false"
            @open="checkParamsChange"
        >
        </multiselect>
    </div>




</template>

<script>

import { store }    from '@root/pages/orders/store';
import Multiselect from "@components/form/Multiselect";
import get from "lodash/get";
import {getAddresses, getContacts} from "@api";



export default {
    store,

    props: {
        requestId: {required: true, type: Number},
        agency: { type: Object},
        provider: {type: Object},
        value: { type: Object }, //should be the times object
        disableWatcher: {}
    },
    data: function() {
        return  {
            isFormOpen: false,
            currentProvider: null,
            currentAgency: null
        }
    },
    computed: {
        contact: {
            get: function () {
                return this.value;
            },
            set: function(value) {
                this.$emit('input', value);
            }
        },

        agencyContactOptions: function () {
            return get(this.$store.state.options, [this.requestId, 'agencyContactOptions'], []).filter(item => item.fullName);
        },
        providerContactOptions: function () {
            return get(this.$store.state.options, [this.requestId, 'providerContactOptions'], []).filter(item => item.fullName);
        },
    },

    methods: {
        focus: function () {
            this.$refs.select.$refs.search.focus();
        },
        checkParamsChange: function () {


            if (this.currentAgency !== this.agency || (this.provider !== this.currentProvider)) {
                this.fetchContactOptions();
            }

        },
        fetchContactOptions: async function () {
            let params = {};
            if (this.agency) {
                params['agency.id'] = this.agency.id;
                this.currentAgency = this.agency;
                const paramsQueryAgency = new URLSearchParams(params).toString()
                const { data: agencyContactOptions } = await getContacts(`?${paramsQueryAgency}`);
                this.$store.commit('updateRequestOptions', {
                    requestId: this.requestId,
                    key: 'agencyContactOptions',
                    newOptions:  agencyContactOptions
                });
            } if (this.provider) {
                params = {};
                params['provider.id'] = this.provider.id;
                this.currentProvider = this.provider;
                const paramsQueryProvider = new URLSearchParams(params).toString()
                const { data: providerContactOptions } = await getContacts(`?${paramsQueryProvider}`);
                this.$store.commit('updateRequestOptions', {
                    requestId: this.requestId,
                    key: 'providerContactOptions',
                    newOptions:  providerContactOptions
                });
            }

        },
    },

    watch: {
        provider: function () {
            if (!this.disableWatcher) {
                this.fetchContactOptions();
            }
        },
        agency: function () {
            if (!this.disableWatcher) {
                this.fetchContactOptions();
            }
        }
    },

    mounted() {
        if (!this.disableWatcher) {
            this.fetchContactOptions();
        }
    },


    components: {
        Multiselect
    }


}
</script>
