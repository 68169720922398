var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "is-full-width", attrs: { title: _vm.localValue } },
    [
      !_vm.textOnly
        ? _c("div", { staticClass: "control is-expanded" }, [
            _c("input", {
              ref: "input",
              staticClass: "input",
              class: _vm.inputClass,
              attrs: {
                type: "text",
                disabled: _vm.disabled,
                placeholder: _vm.placeholder,
                readonly: _vm.readOnlyComputed,
              },
              domProps: { value: _vm.localValue },
              on: { blur: _vm.updateValue, focus: _vm.focus },
            }),
          ])
        : _c("span", [_vm._v(_vm._s(_vm.value))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }