import { getDays } from "@api";

export default {
    state: {
        drawer: {
            isReady: false,
            isLoading: false,
            isExpanded: false,
            isExpandedMax: false,
            expansionHeight: '500px',
            dataHasChanged: false,
            dataIsSaving: false,
        },

    },
    mutations: {

        updateDrawer: function (state, value) {
            state.drawer = {...state.drawer, ...value}
        },

        updateDrawerLoading: function (state, newState) {
            state.drawer.isLoading = newState;
        },

        updateQuickPlaceholders: function (state, { dayID, placeholderType, placeholderID, placeholderData }) {
            const dayIndex         = state.order.days.findIndex(x => x.id === dayID);
            const placeholderIndex = state.order.days[dayIndex][placeholderType].findIndex(x => x.id === placeholderID);
            state.order.days[dayIndex][placeholderType].splice(placeholderIndex, 1, placeholderData);
        },
    },
    actions: {
        fetchDrawerData: function({state}, { orderID }) {
            state.drawer.isReady = true;
            const params = '_groups[]=trip_dashboard_read&_groups[]=modal_read&_groups[]=money_read&_groups[]=quick_edit';
            this.commit('updateDrawerLoading', true);
            getDays('?order.id=' + orderID + '&' + params)
                .then(response => {
                    this.commit('updateOrder',  { days: response.data} );
                    this.commit('updateDrawerLoading', false);
                })
                .catch(err => this.commit('updateDrawerLoading', false))
        },
    },
    getters: {
        hotels: function(state, _, rootState) {
            const requests = []
            if(rootState.order && rootState.order.days) {
                rootState.order.days.forEach((day) => {
                    if(day.hotelPlaceholders) {
                        day.hotelPlaceholders.forEach((hotelPlaceholder) => {
                            if(hotelPlaceholder.requests) {
                                hotelPlaceholder.requests.forEach((request) => requests.push(request))
                            }
                        })
                    }
                })
            }
            return requests
        },
        others: function(state, _, rootState) {
            const requests = []
            if(rootState.order && rootState.order.days) {
                rootState.order.days.forEach((day) => {
                    if(day.otherPlaceholders) {
                        day.otherPlaceholders.forEach((otherPlaceholder) => {
                            if(otherPlaceholder.requests) {
                                otherPlaceholder.requests.forEach((request) => requests.push(request))
                            }
                        })
                    }
                })
            }
            return requests
        },
        ferries: function(state, _, rootState) {
            const requests = []
            if(rootState.order && rootState.order.days) {
                rootState.order.days.forEach((day) => {
                    if(day.ferryPlaceholders) {
                        day.ferryPlaceholders.forEach((ferryPlaceholder) => {
                            if(ferryPlaceholder.requests) {
                                ferryPlaceholder.requests.forEach((request) => requests.push({ ...request, contingent: { ...request.contingent, type: request.type }}))
                            }
                        })
                    }
                })
            }
            return requests;
        },
        nightFerries: function(state, getters) {
            return getters.ferries.filter(f => f.type === 'night');
        },
        dayFerries: function(state, getters) {
            return getters.ferries.filter(f => f.type === 'day');
        },
        airlines: function(state, _, rootState) {
            const requests = []
            if(rootState.order && rootState.order.days) {
                rootState.order.days.forEach((day) => {
                    if(day.airlinePlaceholders) {
                        day.airlinePlaceholders.forEach((airlinePlaceholder) => {
                            if(airlinePlaceholder.requests) {
                                airlinePlaceholder.requests.forEach((request) => requests.push({...request, placeholderInfo: {startAirport: request.startAirport, endAirport: request.endAirport}}))
                            }
                        })
                    }
                })
            }
            return requests
        },
        trains: function(state, _, rootState) {
            const requests = []
            if(rootState.order && rootState.order.days) {
                rootState.order.days.forEach((day) => {
                    if(day.trainPlaceholders) {
                        day.trainPlaceholders.forEach((trainPlaceholder) => {
                            if(trainPlaceholder.requests) {
                                trainPlaceholder.requests.forEach((request) => requests.push(request))
                            }
                        })
                    }
                })
            }
            return requests
        },
        dayTrains: function(state, getters) {
            return getters.trains.filter(f => f.type === 'day');
        },
        nightTrains: function(state, getters) {
            return getters.trains.filter(f => f.type === 'night');
        },

        drawer: function (state) {
            return state.drawer;
        }
    }
}
