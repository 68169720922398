<template>
    <datepicker
        v-bind:config="config"
        v-bind:value="wantedValue"
        v-on:input="update"
        :placeholder="placeholderText"
        ref="timePicker"
    ></datepicker>
</template>



<script>
import Datepicker from 'vue-bulma-datepicker';
import { fullYear, time } from '@utilities/functions';
import debounce from "lodash/debounce";


export default {
    props: {
        value:           { required: true },
        placeholderText: { type: String,  default: '' },
        timeOnly:        { type: Boolean, default: false },
        debounce:        { type: Boolean, default: false}
    },


    computed: {
        wantedValue: function () {
            return this.value ? time(this.value) : null;
        },


        config: function () {
            return {
                enableTime: true,
                time_24hr:  true,
                noCalendar: true,
                allowInput: true,
                onClose: function (_, __, fp) {
                    fp.setDate(fp.input.value, true, 'H:i:s');
                },
            }
        },
    },


    methods: {
        focus: function () {
            this.$refs.timePicker.datepicker.input.select();
        },
        update: function (newValue) {

            if (this.value && !this.timeOnly) {

                // 20.12.2018 12:00:00 => 20.12.2018
                let dateAndTime = fullYear(this.value);
                // Adding the time
                dateAndTime += (' ' + newValue + ':00');


                if(dateAndTime !== this.value) {
                    // Emit the `input` event for the v-model of the parent
                    if(this.debounce) {
                        this.debouncedUpdate(dateAndTime)
                    } else {
                        this.$emit('input', dateAndTime);
                    }
                }
            } else {
                if(this.debounce) {
                    this.debouncedUpdate(newValue + ':00')
                } else {
                    this.$emit('input', newValue + ':00');
                }
            }
        },

        debouncedUpdate: debounce(function(value) {
            this.$emit('input', value);
        }, 300),
    },


    watch: {
        wantedValue: function () {
            if (this.wantedValue === null) {
                this.$refs.timePicker.datepicker.clear();
            }
        }
    },


    components: {
        Datepicker
    }
}
</script>
