<template>
    <div class="is-full-width">
        <div class="control is-expanded" v-if="!textOnly">
            <input
                class="input"
                type="text"
                ref="input"
                v-bind:value="internalValue"
                v-on:blur="updateValue"
                v-on:focus="focus"
                v-bind:class="inputClass"
                v-bind:disabled="disabled"
                v-bind:placeholder="placeholder"
                :readonly="readOnlyComputed"
            />
        </div>
        <span v-else>{{internalValue}}</span>
    </div>


</template>

<script>



export default {
    props: {
        value: { required: true }, //should be the contingent
        type: {type: Object}, //roomType or cabinType (for other ->null)
        property: {type: String, required: true}, //eg. askedAmount

        inputClass: {
            type: String,
            default: ''
        },

        placeholder: {
            type: String,
            default: ''
        },

        fieldName: {
            type: String,
        },

        returnType: {
            type: String,
            default: 'string',
        },

        disabled: { type: Boolean, default: false },
        nullable: { type: Boolean, default: false },
        readOnly: { type: Boolean, default: false },
        readOnlyValue: {
            default: null,
        },
        textOnly: { type: Boolean, default: false },
    },

    data: function() {
        return {
            isFocused: false,
        }
    },

    computed: {
        readOnlyComputed: function () {
            return this.readOnly || (!!this.readOnlyValue && this.readOnlyValue === this.localValue)
        },

        internalValue: {
            get: function() {
                if (!this.isFocused) {
                    let value = this.value;
                    if(this.type) {
                        value = this.value ? this.value[this.fieldName] && Array.isArray(this.value[this.fieldName]) && this.value[this.fieldName].find(room => room.type.type === this.type.type && room.type.persons === this.type.persons) || {} : {};
                    }
                    return value[this.property] || 0;
                }
                return ''
            },
            set: function (value) {
                this.$emit('input', value)
            }
        },
    },

    methods: {
        updateValue: function (event) {
            let inputValue = event.target.value;
            if(this.internalValue != inputValue) {
                let updatedValue = { };
                updatedValue.id = this.value.id
                inputValue = this.returnType === 'int'? parseInt(inputValue): inputValue;
                if(this.type) {
                    updatedValue[this.fieldName] = this.value[this.fieldName]
                    let updatedRoom = updatedValue[this.fieldName]
                    if(!updatedRoom.find(room => room.type.type === this.type.type && room.type.persons === this.type.persons)) {
                        updatedRoom.push({ type: {...this.type}})
                    }
                    const requiredRoom = updatedRoom.find(room => room.type.type === this.type.type && room.type.persons === this.type.persons)
                    if(requiredRoom) {
                        const keyArray = this.property.split('.')
                        this.updateObject(requiredRoom, inputValue, 0, keyArray, keyArray.length)
                    }
                } else {
                    updatedValue[this.property] = inputValue;
                }

                this.$emit('input',  {...this.value, ...updatedValue});
                this.$emit('change', {...this.value, ...updatedValue});
            }
            this.isFocused = false;
        },


        focus: function () {
            if (this.$refs.input) {
                this.$refs.input.select();
                this.isFocused = true;
            }
        },

        updateObject: function(prevObj, newVal, index, keyArray, arrLen) {
            if(index === arrLen - 1) {
                prevObj[keyArray[index]] = parseInt(newVal);
                return;
            }
            if(!prevObj[keyArray[index]]) {
                prevObj[keyArray[index]] = {}
            }
            this.updateObject(prevObj[keyArray[index]], newVal, index+1, keyArray, arrLen)
        },

    },


    watch: {
        value: function () {
            if (!this.value) {
                this.localValue = this.nullable ? null : '';
            }
        }
    },


}
</script>
