<template>
    <div class="is-full-width">
        <popover trigger="hover" class="is-large" v-if="value">
            <i class="material-icons">folder</i>
            <template slot="popover">
                <ul class="has-text-left">
                    <li v-for="request in value.requestsInfo">{{request.date}}: {{request.name}} | {{ request.status }}</li>
                </ul>
            </template>
        </popover>
    </div>
</template>



<script>

import Popover from "@components/Popover";
import {fullYear} from "@utilities/functions";

export default {
    components: {Popover},
    props: {
        value: { required: true },
        isDate: {default: false},
        baseUrl: {default: ''},
        urlId: { default: ''}
    },

    computed: {
        url: function () {
            return this.baseUrl + this.urlId
        },

        transformedValue: function () {
            if(this.isDate && this.value){
                return fullYear(this.value)
            }
            return this.value ? this.value : '';
        }
    },

    component: {
        Popover
    }

}
</script>
