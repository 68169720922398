var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tiptap",
      class: {
        "is-alone": !_vm.isNotAlone,
        "is-not-alone": _vm.isNotAlone,
        "is-fullscreen": _vm.fullscreen,
        resizeable: _vm.resizeable,
        "is-disabled": !_vm.editable,
        "has-extraLines-warnings": _vm.hasTooManyLines,
      },
    },
    [
      _vm.editor
        ? _c(
            "div",
            {
              staticClass: "level tiptap-menu mb-0",
              class: { "is-not-alone": _vm.isNotAlone },
            },
            [
              _c(
                "div",
                { staticClass: "level-left" },
                [
                  !_vm.codeView
                    ? [
                        _vm.hasMenu("fullscreen")
                          ? _c(
                              "button",
                              {
                                class: { "is-active": _vm.fullscreen },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.toggleFullScreen()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.fullscreen
                                        ? "fullscreen_exit"
                                        : "fullscreen"
                                    )
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasMenu("bold")
                          ? _c(
                              "button",
                              {
                                staticClass: "menu-items",
                                class: {
                                  "is-active": _vm.editor.isActive("bold"),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.editor
                                      .chain()
                                      .focus()
                                      .toggleBold()
                                      .run()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("format_bold"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasMenu("italic")
                          ? _c(
                              "button",
                              {
                                staticClass: "menu-items",
                                class: {
                                  "is-active": _vm.editor.isActive("italic"),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.editor
                                      .chain()
                                      .focus()
                                      .toggleItalic()
                                      .run()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("format_italic"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasMenu("strike")
                          ? _c(
                              "button",
                              {
                                staticClass: "menu-items",
                                class: {
                                  "is-active": _vm.editor.isActive("strike"),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.editor
                                      .chain()
                                      .focus()
                                      .toggleStrike()
                                      .run()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("strikethrough_s"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasMenu("underline")
                          ? _c(
                              "button",
                              {
                                class: {
                                  "is-active": _vm.editor.isActive("underline"),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.editor
                                      .chain()
                                      .focus()
                                      .toggleUnderline()
                                      .run()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("format_underline"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasMenu("h1")
                          ? _c(
                              "button",
                              {
                                staticClass: "menu-items",
                                class: {
                                  "is-active": _vm.editor.isActive("heading", {
                                    level: 1,
                                  }),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.editor
                                      .chain()
                                      .focus()
                                      .toggleHeading({ level: 1 })
                                      .run()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        h1\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasMenu("h2")
                          ? _c(
                              "button",
                              {
                                staticClass: "menu-items",
                                class: {
                                  "is-active": _vm.editor.isActive("heading", {
                                    level: 2,
                                  }),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.editor
                                      .chain()
                                      .focus()
                                      .toggleHeading({ level: 2 })
                                      .run()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        h2\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasMenu("bullet_list")
                          ? _c(
                              "button",
                              {
                                staticClass: "menu-items",
                                class: {
                                  "is-active":
                                    _vm.editor.isActive("bulletList"),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.editor
                                      .chain()
                                      .focus()
                                      .toggleBulletList()
                                      .run()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("format_list_bulleted"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasMenu("ordered_list")
                          ? _c(
                              "button",
                              {
                                staticClass: "menu-items",
                                class: {
                                  "is-active":
                                    _vm.editor.isActive("orderedList"),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.editor
                                      .chain()
                                      .focus()
                                      .toggleOrderedList()
                                      .run()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("format_list_numbered"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasMenu("link")
                          ? _c(
                              "button",
                              {
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.setLink.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("insert_link"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasMenu("code")
                    ? _c(
                        "button",
                        {
                          staticClass: "menu-items",
                          class: { "is-active": _vm.editor.isActive("code") },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.editor.chain().focus().toggleCode().run()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("code"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasMenu("table")
                    ? _c(
                        "button",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.editor
                                .chain()
                                .focus()
                                .insertTable({
                                  rows: 3,
                                  cols: 3,
                                  withHeaderRow: true,
                                })
                                .run()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("border_all"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editor.isActive("table")
                    ? _c(
                        "div",
                        {
                          staticClass: "tiptap-submenu",
                          class: { "is-active": true },
                        },
                        [
                          _c(
                            "button",
                            {
                              attrs: {
                                disabled: !_vm.editor.can().addColumnBefore(),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.editor
                                    .chain()
                                    .focus()
                                    .addColumnBefore()
                                    .run()
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "1em",
                                    height: "1em",
                                    preserveAspectRatio: "xMidYMid meet",
                                    viewBox: "0 0 24 24",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      fill: "currentColor",
                                      d: "M13 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h9V2h-9m7 8v4h-7v-4h7m0 6v4h-7v-4h7m0-12v4h-7V4h7M9 11H6V8H4v3H1v2h3v3h2v-3h3v-2Z",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              attrs: {
                                disabled: !_vm.editor.can().addColumnAfter(),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.editor
                                    .chain()
                                    .focus()
                                    .addColumnAfter()
                                    .run()
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "1em",
                                    height: "1em",
                                    preserveAspectRatio: "xMidYMid meet",
                                    viewBox: "0 0 24 24",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      fill: "currentColor",
                                      d: "M11 2a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H2V2h9m-7 8v4h7v-4H4m0 6v4h7v-4H4M4 4v4h7V4H4m11 7h3V8h2v3h3v2h-3v3h-2v-3h-3v-2Z",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              attrs: {
                                disabled: !_vm.editor.can().deleteColumn(),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.editor
                                    .chain()
                                    .focus()
                                    .deleteColumn()
                                    .run()
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "1em",
                                    height: "1em",
                                    preserveAspectRatio: "xMidYMid meet",
                                    viewBox: "0 0 24 24",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      fill: "currentColor",
                                      d: "M6 4v16h2.674c.355.749.84 1.424 1.425 1.998A2.015 2.015 0 0 1 10 22H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v6.674a7.018 7.018 0 0 0-2 1.427V4H6Z",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      fill: "currentColor",
                                      d: "M10 17c0-1.636.786-3.088 2-4a5 5 0 1 1-2 4Zm2 1h6v-2h-6v2Z",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              attrs: {
                                disabled: !_vm.editor.can().addRowBefore(),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.editor
                                    .chain()
                                    .focus()
                                    .addRowBefore()
                                    .run()
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "1em",
                                    height: "1em",
                                    preserveAspectRatio: "xMidYMid meet",
                                    viewBox: "0 0 20 20",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      fill: "currentColor",
                                      d: "M17 20v-8h1V0H2v12h1v8zM9 10V7H6V5h3V2h2v3h3v2h-3v3zm-4 4v-2h10v2zm0 4v-2h10v2z",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              attrs: {
                                disabled: !_vm.editor.can().addRowAfter(),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.editor.chain().focus().addRowAfter().run()
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "1em",
                                    height: "1em",
                                    preserveAspectRatio: "xMidYMid meet",
                                    viewBox: "0 0 20 20",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      fill: "currentColor",
                                      d: "M3 0v8H2v12h16V8h-1V0zm8 10v3h3v2h-3v3H9v-3H6v-2h3v-3zm4-4v2H5V6zm0-4v2H5V2z",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              attrs: {
                                disabled: !_vm.editor.can().deleteRow(),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.editor.chain().focus().deleteRow().run()
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "1em",
                                    height: "1em",
                                    preserveAspectRatio: "xMidYMid meet",
                                    viewBox: "0 0 24 24",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      fill: "currentColor",
                                      d: "M20 6H4v4h8.101a7.018 7.018 0 0 0-1.427 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v4c0 .033 0 .066-.002.099A7.017 7.017 0 0 0 20 8.674V6Z",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      fill: "currentColor",
                                      d: "M20.85 11.81A4.99 4.99 0 0 0 17 10a4.993 4.993 0 0 0-4 2a5 5 0 1 0 7.851-.19ZM20 14v2h-6v-2h6Z",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              attrs: {
                                disabled: !_vm.editor.can().deleteTable(),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.editor.chain().focus().deleteTable().run()
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "1em",
                                    height: "1em",
                                    preserveAspectRatio: "xMidYMid meet",
                                    viewBox: "0 0 24 24",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      fill: "currentColor",
                                      d: "M19 10h-6v11H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v8h-2v-3Zm-8 0H5v4h6v-4Zm0 9v-3H5v3h6Zm2-14v3h6V5h-6Zm-2 0H5v3h6V5Z",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      fill: "currentColor",
                                      d: "M16 18v-2h8v2h-8Z",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              attrs: {
                                disabled: !_vm.editor.can().mergeCells(),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.editor.chain().focus().mergeCells().run()
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "1em",
                                    height: "1em",
                                    preserveAspectRatio: "xMidYMid meet",
                                    viewBox: "0 0 24 24",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      fill: "currentColor",
                                      d: "M5 10H3V4h8v2H5v4m14 8h-6v2h8v-6h-2v4M5 18v-4H3v6h8v-2H5M21 4h-8v2h6v4h2V4M8 13v2l3-3l-3-3v2H3v2h5m8-2V9l-3 3l3 3v-2h5v-2h-5Z",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasMenu("color")
                    ? _c("input", {
                        staticClass: "menu-items color-picker",
                        attrs: { type: "color" },
                        domProps: {
                          value: _vm.editor.getAttributes("textStyle").color,
                        },
                        on: {
                          input: function ($event) {
                            _vm.editor
                              .chain()
                              .focus()
                              .setColor($event.target.value)
                              .run()
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasMenu("undo")
                    ? _c(
                        "button",
                        {
                          staticClass: "menu-items",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.editor.chain().focus().undo().run()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("undo"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasMenu("redo")
                    ? _c(
                        "button",
                        {
                          staticClass: "menu-items",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.editor.chain().focus().redo().run()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("redo"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "level-right" }, [
                _vm.warning
                  ? _c("span", { staticClass: "has-text-danger" }, [
                      _vm._v(_vm._s(_vm.warning)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasMenu("reset")
                  ? _c(
                      "button",
                      {
                        staticClass: "menu-items",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.$emit("reset")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("refresh"),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasTooManyLines
        ? _c(
            "div",
            { staticClass: "warnings is-flex is-flex-direction-column" },
            [
              _vm.hasTooManyLines
                ? _c("h4", { staticClass: "warnings has-text-centered mb-0" }, [
                    _vm._v(
                      "Reduziere den Text auf max " +
                        _vm._s(this.maxLines) +
                        " Zeilen"
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.countNumberOfExtraLines(_vm.editor.getHTML())
                ? _c("h4", { staticClass: "warnings has-text-centered mb-0" }, [
                    _vm._v(
                      "Mehr als " +
                        _vm._s(this.maxCharacters) +
                        " Zeichen = neue Zeile"
                    ),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.codeView
        ? _c("div", { staticClass: "tiptapDiv" }, [
            _c("pre", [_c("code", [_vm._v(_vm._s(_vm.html))])]),
          ])
        : _c("editor-content", {
            ref: "content",
            staticClass: "tiptapDiv",
            attrs: { editor: _vm.editor },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }