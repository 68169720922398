var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shorted
    ? _c("section", [
        _vm.computedElement && _vm.computedElement.document
          ? _c("div", [
              _c(
                "a",
                {
                  attrs: {
                    title: "Herunterladen",
                    href: _vm.downloadDocument(_vm.computedElement.document),
                    download: "",
                    target: "_blank",
                  },
                },
                [
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("attach_file"),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
      ])
    : _c("section", [
        _c(
          "div",
          {
            staticClass: "is-full-width has-text-centered",
            class: {
              "hover-upload-area":
                !_vm.computedElement || !_vm.computedElement.document,
            },
            staticStyle: { height: "100%" },
          },
          [
            _vm.computedElement && _vm.computedElement.document
              ? [
                  _c("loading", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isLoading,
                        expression: "isLoading",
                      },
                    ],
                    staticClass: "in-row is-small",
                    staticStyle: {
                      height: "30px",
                      margin: "0 0 0 5px",
                      padding: "0",
                      width: "unset",
                    },
                    attrs: { "text-in-front": "", "text-after": "" },
                  }),
                  _vm._v(" "),
                  !_vm.isLoading
                    ? [
                        _c("input", {
                          ref: "fileUpload",
                          staticClass: "is-hidden",
                          attrs: { type: "file" },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "level",
                            staticStyle: {
                              "justify-content": "center",
                              height: "100%",
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "button is-max-100 has-text-grey pl-2 pr-2",
                                attrs: {
                                  href: _vm.downloadDocument(
                                    _vm.computedElement.document
                                  ),
                                  download: "",
                                  target: "_blank",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "is-truncated mr-0" },
                                  [_vm._v("download")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteDocument(
                                      _vm.computedElement.document
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("delete"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ]
              : typeof _vm.computedElement === "undefined"
              ? [_c("p", { staticClass: "mt-2" })]
              : [
                  _vm.hasUpload
                    ? _c(
                        "vue-dropzone",
                        {
                          ref: "dropzone",
                          attrs: {
                            id: "element-dropzone",
                            "resize-height": 500,
                            options: _vm.dropzoneOptions,
                            useCustomSlot: "",
                          },
                          on: {
                            "vdropzone-sending": _vm.sendingEvent,
                            "vdropzone-success": _vm.fileSuccess,
                          },
                        },
                        [
                          _vm.isLoading
                            ? _c("loading", {
                                staticClass: "is-small",
                                staticStyle: { height: "30px" },
                                attrs: {
                                  "text-in-front": "",
                                  "text-after": "",
                                },
                              })
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "dz-dropzone is-in-row is-justify-content-center",
                                  attrs: { id: "documentDropZone" },
                                },
                                [
                                  _c(
                                    "i",
                                    {
                                      staticClass: "material-icons mr-0",
                                      staticStyle: { "font-size": "14px" },
                                    },
                                    [_vm._v("upload")]
                                  ),
                                ]
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
          ],
          2
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }