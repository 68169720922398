<template>
    <modal v-bind:visible="visible" v-on:closeModal="$emit('closeModal')" class="is-form">
        <p slot="header">{{ heading }}</p>
        <form slot="content">
            <vue-dropzone
                v-if="enableDropzone"
                ref="dropzone"
                id="modal-dropzone"
                v-bind:options="dropzoneOptions"
                v-on:vdropzone-sending="sendingEvent"
                v-on:vdropzone-success="fileSuccess"
                useCustomSlot
            >
                <slot></slot>
                <div class="dz-area" v-if="dropZone.type === 'image'">
                    <span>Zum Hochladen Datei hier hineinziehen</span>
                </div>
            </vue-dropzone>

            <slot v-else></slot>
        </form>
        <div class="level" slot="footer">
            <button class="button is-cancel" v-on:click="$emit('closeModal')">{{ cancelButton }}</button>

            <button v-if="hasSecondSubmitButton" class="button is-primary mr-2" style="margin-left: auto" v-on:click="saveForm2" :disabled="isSecondButtonLoading || isDisabled">
                <template v-if="!isSecondButtonLoading">{{ secondSaveButton }}</template>
                <i class="material-icons icon-spin" v-else>sync</i>
            </button>
            <button class="button is-primary" v-on:click="saveForm" :disabled="isLoading || isDisabled">
                <template v-if="!isLoading">{{ saveButton }}</template>
                <i class="material-icons icon-spin" v-else>sync</i>
            </button>
        </div>
    </modal>
</template>



<script>
import vue2Dropzone from 'vue2-dropzone';
import { authHeader } from '@utilities/functions';
import Modal from '@components/Modal';

export default {
    props: {
        editTitle: {
            type: String,
            default: 'Bearbeiten'
        },
        createTitle: {
            type: String,
            default: 'Neue'
        },
        submitButton: {
            type: String,
            default: 'Speichern'
        },
        secondSubmitButton: {
            type: String,
            default: ''
        },
        hasSecondSubmitButton: {
            type: Boolean,
        },
        editButton: {
            type: String,
            default: 'Speichern'
        },
        cancelButton: {
            type: String,
            default: 'Abbrechen'
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isSecondButtonLoading: {
            type: Boolean,
            default: false
        },
        dropZone: {
            type: Object,
            default: () => {
                return {
                    url: '/api/images/upload',
                    clickable: '.dz-area',
                    type: 'image',
                    //autoProcessQueue: true,
                }
            }
        }
    },


    data: function() {
        return {
            isDisabled: false,
            dropzoneOptions: {
                url: this.dropZone.url,
                clickable: this.dropZone.clickable,
                //acceptedFiles: '.xlsx',
                maxFilesize: 20,
                headers: authHeader(),
                previewTemplate: this.template(),
                createImageThumbnails: false,
                paramName: "user_file_upload",
                //autoProcessQueue: this.dropZone.autoProcessQueue,
            }
        }
    },

    mounted()  {
        let lastTarget = null;

        if (this.visible) {
            window.addEventListener("dragenter", (e) => {
                lastTarget = e.target; // cache the last target here
                document.getElementById("modal-dropzone").classList.add("dz-drag-hover")
            });

            window.addEventListener("dragleave", (e) => {
                if(e.target === lastTarget || e.target === document) {
                    document.getElementById("modal-dropzone").classList.remove("dz-drag-hover")
                }
            });
        }
    },

    computed: {
        heading: function () {
            return this.$parent.editMode ? this.editTitle : this.createTitle;
        },


        visible: function () {
            return this.$parent.visible;
        },


        saveButton: function () {
            if (this.editButton && this.$parent.editMode) {
                return this.editButton
            }

            return this.submitButton;
        },
        secondSaveButton: function () {
            if (this.editButton && this.$parent.editMode) {
                return this.editButton
            }

            return this.secondSubmitButton;
        },

        enableDropzone: function() {
            return !!this.$listeners.imageUploaded
        }
    },


    methods: {
        authHeader,

        saveForm: function () {
            this.isDisabled = true;
            this.$emit('submit');

            if (this.$parent.editMode) {
                this.$emit('update');

            } else {
                if (!!this.$parent.upload) {
                    this.$emit('upload')

                } else {
                    this.$emit('create');
                }
            }
            this.isDisabled = false;
        },
        saveForm2: function () {
            this.isDisabled = true;
            this.$emit('submit2');
            this.isDisabled = false;
        },

        // Dropzone
        template: function () {
            return `<div class="dz-preview dz-file-preview">
                        <div class="dz-details">
                            <span data-dz-name></span>
                            <div class="dz-error-message">
                                <span data-dz-errormessage></span>
                            </div>
                        </div>
                        <div class="dz-progress">
                            <span class="dz-upload" data-dz-uploadprogress></span>
                        </div>

                        <div class="dz-remove">
                            <i class="material-icons" data-dz-remove>delete</i>
                        </div>
                    </div>`;
        },

        sendingEvent: function (file, xhr, formData) {
            if(this.dropZone.type === 'image'){
                formData.append('title', file.name.split('.').slice(0, -1).join('.'));
                formData.append(this.paramKey, `/api/${this.api}/${this.id}`)
            }
        },

        fileSuccess: function (file, response) {
            this.$emit('imageUploaded', response);
            this.$refs.dropzone.removeFile(file)
        }
    },


    components: {
        Modal,
        vueDropzone: vue2Dropzone
    },
}
</script>
