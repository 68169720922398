import Vuex from 'vuex';

const store = new Vuex.Store({
    state: {
        hotelTimes: []
    },

    mutations: {
        updateState(state, {property, value}) {
            state[property] = value;
        },
    }
});

export { store };
export default store;
