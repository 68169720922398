<template>
    <div class="is-full-width" :title="transformedValue">
        <a :href="url" v-if="url" target="_blank">{{transformedValue}}</a>
        <span v-else>{{transformedValue}}</span>
    </div>
</template>



<script>


import {fullYear} from "@utilities/functions";

export default {
    props: {
        value: { required: true },
        isDate: {default: false},
        baseUrl: {default: ''},
        urlId: { default: ''},
        keyValueOptionPath: {type: String, default: null},
        generalOptions: {}
    },

    computed: {
        url: function () {
            return this.baseUrl + this.urlId
        },

        transformedValue: function () {
            if(this.isDate && this.value){
                return fullYear(this.value)
            }
            if(this.generalOptions && this.keyValueOptionPath && this.generalOptions[this.keyValueOptionPath]) {
                let option = this.generalOptions[this.keyValueOptionPath].find(object => object.key === this.value);
                return option ? option.value : '';
            }
            return this.value ? this.value : '';
        }
    }

}
</script>
