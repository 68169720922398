<template>
    <node-view-wrapper class="code-block">
      <!-- <select contenteditable="false" v-model="selectedLanguage">
        <option :value="null">
          auto
        </option>
        <option disabled>
          —
        </option>
        <option v-for="(language, index) in languages" :value="language" :key="index">
          {{ language }}
        </option>
      </select> -->
      <pre><code><node-view-content /></code></pre>
    </node-view-wrapper>
  </template>
  
  <script>
    import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2'
    export default {
      components: {
        NodeViewWrapper,
        NodeViewContent,
      },
    
      props: nodeViewProps,
    
      data() {
        return {
          languages: this.extension.options.lowlight.listLanguages(),
        }
      },
    
      computed: {
        selectedLanguage: {
            get(){
                return this.node.attrs.language
            },
            set(){
                this.extension.options.lowlight.listLanguages()
            }
        },
      },
    }
    </script>
      