<template>
    <div class="tiptap"
        :class="{'is-alone' : !isNotAlone, 'is-not-alone' : isNotAlone, 'is-fullscreen': fullscreen, resizeable, 'is-disabled': !editable, 'has-extraLines-warnings': hasTooManyLines}">

        <div class="level tiptap-menu mb-0" v-if="editor" :class="{'is-not-alone' : isNotAlone}">
                <div class="level-left">
                    <template v-if="!codeView">
                        <button v-if="hasMenu('fullscreen')" type="button" :class="{ 'is-active': fullscreen }"
                                @click.prevent="toggleFullScreen()">
                            <i class="material-icons">{{ fullscreen ? 'fullscreen_exit' : 'fullscreen' }}</i>
                        </button>
                        <button v-if="hasMenu('bold')" @click.prevent="editor.chain().focus().toggleBold().run()" class="menu-items"
                                :class="{ 'is-active': editor.isActive('bold') }">
                            <i class="material-icons">format_bold</i>
                        </button>
                        <button v-if="hasMenu('italic')" @click.prevent="editor.chain().focus().toggleItalic().run()"
                                class="menu-items" :class="{ 'is-active': editor.isActive('italic') }">
                            <i class="material-icons">format_italic</i>
                        </button>
                        <button v-if="hasMenu('strike')" @click.prevent="editor.chain().focus().toggleStrike().run()"
                                class="menu-items" :class="{ 'is-active': editor.isActive('strike') }">
                            <i class="material-icons">strikethrough_s</i>
                        </button>
                        <button v-if="hasMenu('underline')" @click.prevent="editor.chain().focus().toggleUnderline().run()"
                                :class="{ 'is-active': editor.isActive('underline') }">
                            <i class="material-icons">format_underline</i>
                        </button>
                        <button v-if="hasMenu('h1')" @click.prevent="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                                class="menu-items" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                            h1
                        </button>
                        <button v-if="hasMenu('h2')" @click.prevent="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                                class="menu-items" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                            h2
                        </button>
                        <button v-if="hasMenu('bullet_list')" @click.prevent="editor.chain().focus().toggleBulletList().run()"
                                class="menu-items" :class="{ 'is-active': editor.isActive('bulletList') }">
                            <i class="material-icons">format_list_bulleted</i>
                        </button>
                        <button v-if="hasMenu('ordered_list')" @click.prevent="editor.chain().focus().toggleOrderedList().run()"
                                class="menu-items" :class="{ 'is-active': editor.isActive('orderedList') }">
                            <i class="material-icons">format_list_numbered</i>
                        </button>
                        <button v-if="hasMenu('link')" type="button" @click.prevent="setLink">
                            <i class="material-icons">insert_link</i>
                        </button>
                    </template>
                    <button v-if="hasMenu('code')" @click.prevent="editor.chain().focus().toggleCode().run()" class="menu-items"
                            :class="{ 'is-active': editor.isActive('code') }">
                        <i class="material-icons">code</i>
                    </button>
                    <button v-if="hasMenu('table')"
                            @click.prevent="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()">
                        <i class="material-icons">border_all</i>
                    </button>
                    <div v-if="editor.isActive('table')" class="tiptap-submenu" :class="{ 'is-active': true }">
                        <button @click.prevent="editor.chain().focus().addColumnBefore().run()"
                                :disabled="!editor.can().addColumnBefore()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                 preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                                <path fill="currentColor"
                                      d="M13 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h9V2h-9m7 8v4h-7v-4h7m0 6v4h-7v-4h7m0-12v4h-7V4h7M9 11H6V8H4v3H1v2h3v3h2v-3h3v-2Z" />
                            </svg>
                        </button>
                        <button @click.prevent="editor.chain().focus().addColumnAfter().run()"
                                :disabled="!editor.can().addColumnAfter()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                 preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                                <path fill="currentColor"
                                      d="M11 2a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H2V2h9m-7 8v4h7v-4H4m0 6v4h7v-4H4M4 4v4h7V4H4m11 7h3V8h2v3h3v2h-3v3h-2v-3h-3v-2Z" />
                            </svg>
                        </button>
                        <button @click.prevent="editor.chain().focus().deleteColumn().run()"
                                :disabled="!editor.can().deleteColumn()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                 preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                                <path fill="currentColor"
                                      d="M6 4v16h2.674c.355.749.84 1.424 1.425 1.998A2.015 2.015 0 0 1 10 22H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v6.674a7.018 7.018 0 0 0-2 1.427V4H6Z" />
                                <path fill="currentColor" d="M10 17c0-1.636.786-3.088 2-4a5 5 0 1 1-2 4Zm2 1h6v-2h-6v2Z" />
                            </svg>
                        </button>
                        <button @click.prevent="editor.chain().focus().addRowBefore().run()"
                                :disabled="!editor.can().addRowBefore()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                 preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20">
                                <path fill="currentColor"
                                      d="M17 20v-8h1V0H2v12h1v8zM9 10V7H6V5h3V2h2v3h3v2h-3v3zm-4 4v-2h10v2zm0 4v-2h10v2z" />
                            </svg>
                        </button>
                        <button @click.prevent="editor.chain().focus().addRowAfter().run()" :disabled="!editor.can().addRowAfter()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                 preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20">
                                <path fill="currentColor"
                                      d="M3 0v8H2v12h16V8h-1V0zm8 10v3h3v2h-3v3H9v-3H6v-2h3v-3zm4-4v2H5V6zm0-4v2H5V2z" />
                            </svg>
                        </button>
                        <button @click.prevent="editor.chain().focus().deleteRow().run()" :disabled="!editor.can().deleteRow()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                 preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                                <path fill="currentColor"
                                      d="M20 6H4v4h8.101a7.018 7.018 0 0 0-1.427 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v4c0 .033 0 .066-.002.099A7.017 7.017 0 0 0 20 8.674V6Z" />
                                <path fill="currentColor"
                                      d="M20.85 11.81A4.99 4.99 0 0 0 17 10a4.993 4.993 0 0 0-4 2a5 5 0 1 0 7.851-.19ZM20 14v2h-6v-2h6Z" />
                            </svg>
                        </button>
                        <button @click.prevent="editor.chain().focus().deleteTable().run()" :disabled="!editor.can().deleteTable()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                 preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                                <path fill="currentColor"
                                      d="M19 10h-6v11H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v8h-2v-3Zm-8 0H5v4h6v-4Zm0 9v-3H5v3h6Zm2-14v3h6V5h-6Zm-2 0H5v3h6V5Z" />
                                <path fill="currentColor" d="M16 18v-2h8v2h-8Z" />
                            </svg>
                        </button>
                        <button @click.prevent="editor.chain().focus().mergeCells().run()" :disabled="!editor.can().mergeCells()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                 preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                                <path fill="currentColor"
                                      d="M5 10H3V4h8v2H5v4m14 8h-6v2h8v-6h-2v4M5 18v-4H3v6h8v-2H5M21 4h-8v2h6v4h2V4M8 13v2l3-3l-3-3v2H3v2h5m8-2V9l-3 3l3 3v-2h5v-2h-5Z" />
                            </svg>
                        </button>
                    </div>
                    <input v-if="hasMenu('color')" type="color"
                           @input="editor.chain().focus().setColor($event.target.value).run()" class="menu-items color-picker"
                           :value="editor.getAttributes('textStyle').color">
                    <button v-if="hasMenu('undo')" @click.prevent="editor.chain().focus().undo().run()" class="menu-items">
                        <i class="material-icons">undo</i>
                    </button>
                    <button v-if="hasMenu('redo')" @click.prevent="editor.chain().focus().redo().run()" class="menu-items">
                        <i class="material-icons">redo</i>
                    </button>
                </div>

                <div class="level-right">
                    <span class="has-text-danger" v-if="warning">{{warning}}</span>
                    <button v-if="hasMenu('reset')" class="menu-items" @click.prevent="$emit('reset')"><i class="material-icons">refresh</i></button>
                </div>

        </div>
        <div class="warnings is-flex is-flex-direction-column" v-if="hasTooManyLines">
            <h4 class="warnings has-text-centered mb-0" v-if="hasTooManyLines">Reduziere den Text auf max {{this.maxLines}} Zeilen</h4>
            <h4 class="warnings has-text-centered mb-0" v-if="countNumberOfExtraLines(editor.getHTML())">Mehr als {{this.maxCharacters}} Zeichen = neue Zeile</h4>
        </div>
        <div v-if="codeView" class="tiptapDiv">
            <pre><code>{{ html }}</code></pre>
        </div>
        <editor-content v-else class="tiptapDiv" :editor="editor" ref="content"></editor-content>


    </div>
</template>
<!-- <template>
    <div v-click-outside="onBlur"  class="tiptap" :class="{'is-alone' : !isNotAlone, 'is-not-alone' : isNotAlone, 'is-fullscreen': fullscreen, resizeable, 'is-disabled': !editable}">
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive, getMarkAttrs }">
            <div class="tiptap-menu" :class="{'is-not-alone' : isNotAlone}">
                <button v-if="hasMenu('fullscreen')" type="button" :class="{ 'is-active': fullscreen }" @click.prevent="toggleFullScreen()">
                    <i class="material-icons">{{ fullscreen ? 'fullscreen_exit' : 'fullscreen' }}</i>
                </button>
                <template v-if="!codeView">
                    <button v-if="hasMenu('bold')" type="button" :class="{ 'is-active': isActive.bold() }" @click.prevent="commands.bold">
                        <i class="material-icons">format_bold</i>
                    </button>
                    <button v-if="hasMenu('italic')" type="button" :class="{ 'is-active': isActive.italic() }" @click.prevent="commands.italic">
                        <i class="material-icons">format_italic</i>
                    </button>
                    <button v-if="hasMenu('underline')" type="button" :class="{ 'is-active': isActive.underline() }" @click.prevent="commands.underline">
                        <i class="material-icons">format_underline</i>
                    </button>
                    <button
                        v-if="hasMenu('h1')"
                        type="button"
                        :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                        @click.prevent="commands.heading({ level: 1 })"
                    ><span>H1</span></button>
                    <button
                        v-if="hasMenu('h2')"
                        type="button"
                        :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                        @click.prevent="commands.heading({ level: 2 })"
                    ><span>H2</span></button>
                    <button
                        v-if="hasMenu('bullet_list')"
                        type="button"
                        :class="{ 'is-active': isActive.bullet_list() }" @click.prevent="commands.bullet_list">
                        <i class="material-icons">format_list_bulleted</i>
                    </button>
                    <button v-if="hasMenu('ordered_list')" type="button" :class="{ 'is-active': isActive.ordered_list() }" @click.prevent="commands.ordered_list">
                        <i class="material-icons">format_list_numbered</i>
                    </button>
                    <button v-if="hasMenu('link')" type="button" :class="{ 'is-active': isActive.link() }" @click.prevent="showLinkMenu(getMarkAttrs('link'))">
                        <i class="material-icons">insert_link</i>
                    </button>
                    <button v-if="hasMenu('table')" type="button" :class="{ 'is-active': isActive.table() }" @click.prevent="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })">
                        <i class="material-icons">border_all</i>
                    </button>
                </template>
                <button v-if="hasMenu('code')" type="button" :class="{'is-active': codeView}" @click.prevent="toggleCodeView()">
                    <i class="material-icons">code</i>
                </button>

                <input-color v-if="hasMenu('color')" @input="color => commands.textcolor({ color })" />

                <button v-if="hasMenu('undo')" type="button" @click.prevent="commands.undo">
                    <i class="material-icons">undo</i>
                </button>

               <div class="tiptap-submenu" :class="{ 'is-active': isActive.table() }">
                    <button type="button" @click.prevent="commands.deleteTable" title="Delete tabl">
                        <delete-table-icon class="svg-inline" />
                    </button>
                    <button type="button" @click.prevent="commands.addColumnBefore" title="Add column before">
                        <add-col-before-icon class="svg-inline" />
                    </button>
                    <button type="button" @click.prevent="commands.addColumnAfter" title="Add column after">
                        <add-col-after-icon class="svg-inline" />
                    </button>
                    <button type="button" @click.prevent="commands.deleteColumn" title="Delete column">
                        <delete-row-icon class="svg-inline" />
                    </button>
                    <button type="button" @click.prevent="commands.addRowBefore" title="Add row before">
                        <add-row-before-icon class="svg-inline" />
                    </button>
                    <button type="button" @click.prevent="commands.addRowAfter" title="Add row after">
                        <add-row-after-icon class="svg-inline" />
                    </button>
                    <button type="button" @click.prevent="commands.deleteRow" title="Delete row">
                        <delete-row-icon class="svg-inline" />
                    </button>
                    <button type="button" @click.prevent="commands.toggleCellMerge" title="Toggle cell merge">
                        <combine-cells-icon class="svg-inline" />
                    </button>
                </div>

                <form v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                    <input class="input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu" @blur="hideLinkMenu" />
                    <button @click.prevent="setLinkUrl(commands.link, null)">
                        <i class="material-icons">close</i>
                    </button>
                </form>
            </div>
        </editor-menu-bar>

        <div v-if="codeView" class="tiptap-content">
            <pre><code>{{ html }}</code></pre>
        </div>
        <editor-content v-else class="tiptap-content" :editor="editor" ref="content"></editor-content>
    </div>
</template> -->

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import { Color } from '@tiptap/extension-color'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import CodeBlock from '@tiptap/extension-code-block'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import BulletList from '@tiptap/extension-bullet-list'
import Document from '@tiptap/extension-document'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import { lowlight } from 'lowlight/lib/all.js'
import CodeBlockComponent from "./CodeBlockComponent.vue"

// import {
//     Bold,
//     Italic,
//     Underline,
//     Heading,
//     ListItem,
//     BulletList,
//     OrderedList,
//     Link,
//     History,
//     Table,
//     TableHeader,
//     TableCell,
//     TableRow,
//     HardBreak,
//     CodeBlockHighlight,
//     Code
// } from 'tiptap-extensions'

import debounce from 'lodash/debounce'
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import { getStorageSize, setStorageSize } from '@utilities/functions';

// import { InputColor } from '@components/form'
// import TextColor from './TextColor'
// import { methods } from 'vue2-dropzone';

const CustomTableCell = TableCell.extend({
    addAttributes() {
        return {
            // extend the existing attributes …
            ...this.parent?.(),

            // and add a new one …
            backgroundColor: {
                default: null,
                parseHTML: element => element.getAttribute('data-background-color'),
                renderHTML: attributes => {
                    return {
                        'data-background-color': attributes.backgroundColor,
                        style: `background-color: ${attributes.backgroundColor}`,
                    }
                },
            },
        }
    },
})

export default {
    components: {
        EditorContent,
    },
    props: {
        value: {
            required: true,
            //validator: prop => typeof prop === 'string' || prop === null
        },

        preview: {
            default: ''
        },

        menuItems: {
            type: Array,
            default: () => ['fullscreen', 'bold', 'italic', 'underline', 'h1', 'h2', 'bullet_list', 'ordered_list', 'link', 'table', 'color', 'undo', 'strike', 'redo'],
        },

        warning: {
            type: String,
            default: ''
        },

        codeOnly: {
            type: Boolean,
            default: false,
        },

        resizeable: {
            type: Boolean,
            default: true,
        },

        isNotAlone: {
            type: Boolean,
            default: false,
        },

        editable: {
            type: Boolean,
            default: true,
        },

        defaultSize: {
            type: Number,
            default: null,
        },
        maxLines: {
            type: Number,
            default: 0
        },
        maxCharacters: {
            type: Number,
            default: 0
        },
        noTrailingBreak: {
            type: Boolean,
            default: true
        }
    },

    // components: {
    //     EditorContent,
    //     EditorMenuBar,

    //     InputColor,

    //     Icons
    //     AddColAfterIcon,
    //     AddColBeforeIcon,
    //     AddRowAfterIcon,
    //     AddRowBeforeIcon,
    //     CombineCellsIcon,
    //     DeleteColIcon,
    //     DeleteRowIcon,
    //     DeleteTableIcon
    // },

    data() {
        return {
            // Create an `Editor` instance with some default content. The editor is
            // then passed to the `EditorContent` component as a `prop`
            editor: null,
            linkUrl: null,
            linkMenuIsActive: false,
            fullscreen: false,
            codeView: false,
            html: this.value,
            hasFocus: false,
            resizeableComponent: this.resizeable,
            oldText:  this.value,
        }
    },
    computed: {
        hasTooManyLines () {
            return this.countNumberOfLines(this.oldText) > this.maxLines;
        },
    },

    methods: {
        countNumberOfLines (textInput) {
            if(!this.editor || !this.maxLines) {
                return false;
            }
            const parser = new DOMParser();
            let doc = parser.parseFromString(textInput, "text/html");
            let elements = doc.getElementsByTagName('p');
            let length = elements.length;

            elements.forEach((element) => {
                length += (element.innerHTML.match(/<br>/g) || []).length;
            });

            return (length + this.countNumberOfExtraLines(textInput));
        },

        countNumberOfExtraLines(textInput) {
            if(!this.editor || !this.maxCharacters) {
                return 0;
            }
            let count = 0;
            let text = '';

            const parser = new DOMParser();
            let doc = parser.parseFromString(textInput, "text/html");
            let elements = doc.getElementsByTagName('p');
            elements.forEach((element, index) => {
                element.innerHTML.split('<br>').forEach((textPerLine, index) => {
                    text = textPerLine.replace(/<\/?[^>]+(>|$)/g, "");

                    count += Math.floor( text.length/this.maxCharacters );
                })
            });
            return count;
        },

        formatValue(string) {


            if(!this.hasTooManyLines) {
                if(this.countNumberOfLines(string) > this.maxLines) {
                    const parser = new DOMParser();
                    let oldDoc = parser.parseFromString(this.oldText, "text/html");
                    string = oldDoc.body.innerHTML
                }
            }
            return string;
        },

        onFocus: function () {
            if (!this.hasFocus) {
                this.hasFocus = true;
                this.$emit('focus')
                if (this.preview !== '' || (this.value === '' && this.html === '')) {
                    this.editor.commands.setContent(this.value);
                }
            }
        },

        setLink() {
            const previousUrl = this.editor.getAttributes('link').href
            const url = window.prompt('URL', previousUrl)

            // cancelled
            if (url === null) {
                return
            }

            // empty
            if (url === '') {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .unsetLink()
                    .run()

                return
            }

            // update link
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: url })
                .run()
        },
        onBlur: function () {
            if (this.hasFocus) {
                this.hasFocus = false;
                this.$emit('blur')

                if (!['', null].includes(this.preview)) {
                    this.editor.setContent(this.preview);
                }
            }
        },

        hasMenu: function (item) {
            return this.menuItems.includes(item);
        },

        // showLinkMenu(attrs) {
        //     this.linkUrl = attrs.href;
        //     this.linkMenuIsActive = true;
        //     this.$nextTick(() => {
        //         this.$refs.linkInput.focus()
        //     })
        // },

        // hideLinkMenu() {
        //     this.linkUrl = null;
        //     this.linkMenuIsActive = false
        // },

        // setLinkUrl(command, url) {
        //     command({ href: url });
        //     this.hideLinkMenu()
        // },

        toggleFullScreen() {
            this.fullscreen = !this.fullscreen;
            this.resizeableComponent = !this.fullscreen;
        },

        toggleCodeView() {
            this.codeView = !this.codeView
        },

        updateHeight: debounce(function (height) {
            this.$emit('resize', height);
            if (!this.defaultSize) {
                setStorageSize(window.location.href, height)
            }
        }, 1000)
    },

    mounted() {

        this.$el.addEventListener('focusin', this.onFocus);
        ListItem.config.addKeyboardShortcuts = function () {
            return {
                Enter: () => this.editor.commands.splitListItem(this.name),
                Tab: () => {
                    if (this.editor.isActive("codeBlock")) {
                        return this.editor.commands.insertContent("    ");
                    } else {
                        this.editor.commands.sinkListItem(this.name)
                    }
                },
                'Shift-Tab': () => this.editor.commands.liftListItem(this.name),
            };
        },
            CodeBlock.config.addKeyboardShortcuts = function () {
                return {
                    'Mod-Alt-c': () => this.editor.commands.toggleCodeBlock(),
                    // remove code block when at start of document or code block is empty
                    Backspace: () => {

                    },
                    // exit node on triple enter
                    Enter: ({ editor }) => {

                    },
                    // exit node on arrow down
                    ArrowDown: ({ editor }) => {

                    }
                };
            }

        let height = null;
        if (this.defaultSize) {
            height = this.defaultSize;
        }


        this.editor = new Editor({
            content: this.value,
            editable: this.editable,

            ...height && {
                editorProps: {
                    attributes: {
                        style: 'height: ' + height + 'px;',
                    },
                }
            },


            extensions: [
                StarterKit,
                Underline,

                Table,
                TableRow,
                TableHeader,
                Document,
                Text,
                CustomTableCell,
                TextStyle,
                Color,
                CodeBlock,
                CodeBlockLowlight.extend({
                    addNodeView() {
                        return VueNodeViewRenderer(CodeBlockComponent)
                    },
                }).configure({
                    lowlight,
                    defaultLanguage: 'twig',
                }),
                BulletList,
                OrderedList,
                ListItem,

            ],
            onUpdate: () => {
                let text = this.editor.getHTML()
                if(this.maxLines) {
                    text = this.formatValue(text)
                    if(text !== this.editor.getHTML()) {
                        const { from, to } = this.editor.state.selection;
                        this.editor.commands.setContent(text);
                        this.editor.commands.setTextSelection({ from: from - 1, to: to - 1 });
                    }
                }
                // HTML
                this.oldText = text;
                this.$emit('input', text)
                if (text === '<p></p>' && this.codeOnly) {
                    this.editor.commands.setCodeBlock()
                }
                // JSON
                // this.$emit('input', this.editor.getJSON())
            },
        })
        if (this.codeOnly) {
            this.editor.commands.setCodeBlock()
        }
    },

    beforeDestroy() {
        this.editor.destroy()
    },

    watch: {
        value: function (value) {
            if ((this.value !== value || this.editor.getHTML() !== value) && (this.hasFocus || !this.preview)) {
                this.editor.commands.setContent(this.value);
            }
        },
        preview: function (value) {
            if ((this.preview !== value || value !== this.editor.getHTML()) && !this.hasFocus) {
                this.editor.commands.setContent(value);
            }
        },
        editable() {
            this.editor.setOptions({
                editable: this.editable,
            })
        },
    },

    // directives: {
    //     clickOutside: vClickOutside.directive
    // }
}
</script>
