var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "multiselect",
    {
      class: _vm.myClass,
      attrs: {
        options: _vm.templatesFiltered,
        label: "name",
        "track-by": "id",
        placeholder: "Wählen Sie eine Vorlage",
        "max-height": 400,
      },
      on: { select: _vm.fillEmail },
      scopedSlots: _vm._u([
        {
          key: "option",
          fn: function (props) {
            return [
              _c(
                "span",
                { staticClass: "option__name", class: props.option.class },
                [
                  props.option.icon
                    ? _c(
                        "i",
                        {
                          staticClass: "material-icons",
                          class: props.option.classes,
                        },
                        [_vm._v(_vm._s(props.option.icon))]
                      )
                    : _vm._e(),
                  _vm._v(" " + _vm._s(props.option.name)),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.tagSelected,
        callback: function ($$v) {
          _vm.tagSelected = $$v
        },
        expression: "tagSelected",
      },
    },
    [
      _vm._v(" "),
      _vm.value && _vm.value.length === 0
        ? _c("span", { attrs: { slot: "caret" }, slot: "caret" })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }